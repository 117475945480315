import Box from "@mui/material/Box";
import {useCallback, useEffect, useState} from "react";
import DashboardComponent from "./dashboard";
import {axiosInstance} from "../../config/interceptors";
import {Constants, Errors, SnackbarMessages} from "../../contstants";
import {useDispatch} from "react-redux";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {buildRequestHeaders, convertToDropdownData} from "../../utils";

const columns = [
    {field: 'firstName', headerName: 'First Name', width: 200},
    {field: 'lastName', headerName: 'Last Name', width: 200},
    {field: 'email', headerName: 'Email', width: 250},
    {
        field: 'role',
        headerName: 'Role',
        width: 100,
        valueFormatter: ({value}) => value && value.name
    },
    {
        field: 'lastModifiedDate',
        headerName: 'Date Last Modified',
        width: 200,
        valueFormatter: ({value}) => value && new Date(value).toLocaleString()
    },
    {
        field: 'createdDate',
        headerName: 'Date Created',
        width: 200,
        valueFormatter: ({value}) => value && new Date(value).toLocaleString()
    },
];

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
    },
    {
        name: 'firstName',
        required: true,
        ignore: false,
        label: 'First Name',
        type: 'string'
    },
    {
        name: 'lastName',
        required: true,
        ignore: false,
        label: 'Last Name',
        type: 'string'
    },
    {
        name: 'email',
        required: true,
        ignore: false,
        label: 'Email',
        type: 'string'
    },
    {
        name: 'role',
        required: true,
        ignore: false,
        label: 'Role',
        type: 'string',
        selectData: [],
        dataName: 'role',
        fieldType: 'select'
    },
]

const AdminUserDashboard = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});

    const dispatch = useDispatch();
    let snackbarMessage = {};

    useEffect(() => {
        axiosInstance.get('/admin/roles?admin=true', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, role: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });
    }, [])

    const getAllRowData = () => axiosInstance.get('/admin/dashboard-users', buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = (id) => axiosInstance.get(`/admin/dashboard-user/${id}`, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            if (!field.ignore) {
                let value = params[field.name];
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        let params = {
            ...formData,
        }

        let url = '/admin/dashboard-user/update';
        if (formMode === Constants.SAVE_MODE) {
            params.role = {id: params.role};
            url = '/admin/dashboard-user/new';
        }

        if (!validateForm(params)) {
            axiosInstance.post(url, {
                ...params
            }, buildRequestHeaders()).then(response => {
                getAllRowData();

                snackbarMessage = SnackbarMessages.success.save
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};

                if (callback) {
                    callback();
                }
                setFormMode(Constants.SAVE_MODE);
            });
        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }


    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`/admin/dashboard-user/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);
            snackbarMessage = SnackbarMessages.success.delete;
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.delete;

            console.error(error);
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};

            if (callback) {
                callback();
            }
        });
    }, [selectedRowData])

    return (
        <Box>
            <DashboardComponent
                dashboardName="Dashboard User"
                columns={columns}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
                dropdownData={dropdownData}
            />
        </Box>
    )
}

export default AdminUserDashboard;
