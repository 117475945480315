import Box from "@mui/material/Box";
import {DataGrid} from '@mui/x-data-grid';

const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'firstName', headerName: 'First name', width: 130},
    {field: 'lastName', headerName: 'Last name', width: 130},
    {field: 'post', headerName: 'Post', width: 90},
    {field: 'mobileNumber', headerName: 'Mobile Number', width: 160},
    {field: 'status', headerName: 'Status', width: 80},
];

function EmployeesDesktopPage() {


    return (
        <Box sx={{
            height: '100%'
        }}>
            <DataGrid
                rows={[]}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 5},
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
            />
        </Box>
    );
}

export default EmployeesDesktopPage;
