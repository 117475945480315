import {Alert, Button, FormControlLabel, MenuItem, Select, styled, TextField, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import Clock from "../components/clock";
import TimeInIcon from "@mui/icons-material/MoreTime";
import {connect, useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {axiosInstance} from "../config/interceptors";
import {
    buildRequestHeaders,
    calculateHoursFromDuration,
    calculateMinsFromDuration,
    getDurationInMins,
    isSameDay
} from "../utils";
import dayjs from "dayjs";
import {setHeaderTitle, setShowSnackbar} from "../redux/action-creators/ui";
import axios from "axios";
import Switch from "@mui/material/Switch";
import {SnackbarMessages, SnackbarTypes} from "../contstants";
import {useNavigate} from "react-router-dom";
import Slider from "../components/slider";
import CircularProgress from "@mui/material/CircularProgress";

const initialLog = {
    employee: {id: null},
    ipAddress: null,
    notes: '',
}

const MaterialUISwitch = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

function HomePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const {firstName = '', lastName = '', companyPost = '', id = null} = user;

    const [log, setLog] = useState(initialLog);
    const [ip, setIP] = useState("");
    const [shiftHours, setShiftHours] = useState(0);
    const [hasTicked, setHasTicked] = useState();
    const [snackbarsShown, setSnackbarsShown] = useState([]);
    // False = CLOCK IN
    // True = CLOCK OUT
    const [hasTimeInEntry, sethasTimeInEntry] = useState(false);
    const [timelogSubmitted, setTimelogSubmitted] = useState("default");

    useEffect(() => {
        dispatch(setHeaderTitle('Attendance'));

        if (id) {
            axiosInstance.get(`/timesheet/${id}/today`, buildRequestHeaders())
                .then(response => {
                    const lastLog = response.data;
                    setLog(lastLog);

                    if (isSameDay(new Date(lastLog.timeIn), new Date()) && !lastLog.timeOut) {
                        sethasTimeInEntry(true);
                        // setNotes(lastLog.notes)
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }

        axios.get("https://api.ipify.org/?format=json").then(response => {
            setIP(response.data.ip);
        });
    }, []);

    useEffect(() => {
        if (log && log.timeIn && !log.timeOut) {
            sethasTimeInEntry(true);
        } else if (log && log.timeOut) {
            // Reset once the user has logged out
            sethasTimeInEntry(false);
        }
    }, [log])


    const noteOnChange = (event) => {
        setLog({
            ...log,
            notes: event.target.value
        });
    }

    const clockInHandler = () => {
        let timesheet;
        let currentDate = new Date();
        axiosInstance.get(`/timesheet/${id}/last`, buildRequestHeaders())
            .then(response => {
                timesheet = response.data;
                if (isSameDay(currentDate, new Date(timesheet.createDate))) {
                    // What to do if same day??
                } else {
                    if (!hasTimeInEntry) {
                        axiosInstance.post('/timesheet/time-in', {
                            employee: {id: user.id},
                            ipAddress: ip,
                            notes: log.notes,
                            actualHours: shiftHours,
                        }, buildRequestHeaders())
                            .then(response => {
                                setLog(response.data);
                                console.log("RESPONSE AFTER TIME-IN", response.data)
                                navigate("/timesheets", {replace: true});
                            }).catch(error => {
                            console.error(error);
                        });
                    }
                }

                setLog(response.data);
                setTimelogSubmitted("success");
            })
            .catch(error => {
                console.error(error);
                setTimelogSubmitted("fail");
            });

    }

    const clockOutHandler = () => {
        let timesheet;
        axiosInstance.get(`/timesheet/${id}/last`, buildRequestHeaders())
            .then(response => {
                timesheet = response.data;
                axiosInstance.post('/timesheet/time-out', {
                    id: timesheet.id,
                    employee: {id: user.id},
                    ipAddress: ip,
                    notes: log.notes,
                    actualHours: shiftHours,
                }, buildRequestHeaders())
                    .then(response => {
                        setLog(response.data);
                    }).catch(error => {
                    console.error(error);
                    setTimelogSubmitted("fail");
                });
                // }

                setTimelogSubmitted("success");
            })
            .catch(error => {
                console.error(error);
            });
    }

    const renderHoursMenuItem = () => {
        let map = [];

        for (let x = 1; x <= 24; x++) {
            map.push(<MenuItem value={x}>{x} {x === 1 ? 'hour' : 'hours'}</MenuItem>)
        }

        return map;
    }

    const lastTimeInDurationInMins = log && log.timeIn && getDurationInMins(log.timeIn, dayjs());
    const lastTimeOutDurationInMins = log && log.timeOut && getDurationInMins(log.timeOut, dayjs());

    if (log && (log.timeIn && !log.timeOut) && snackbarsShown) {
        if (parseInt(calculateHoursFromDuration(lastTimeInDurationInMins)) < 20 && !snackbarsShown.includes("hasTimeIn")) {
            dispatch(setShowSnackbar(SnackbarMessages.hours.hasTimeIn));
            setSnackbarsShown(snackbarsShown.push("hasTimeIn"));
        } else if ((parseInt(calculateHoursFromDuration(lastTimeInDurationInMins)) >= 20) && !snackbarsShown.includes("noTimeOut")) {
            dispatch(setShowSnackbar(SnackbarMessages.hours.noTimeOut));
            setSnackbarsShown(snackbarsShown.push("noTimeOut"));
        }
    } else if (log.timeIn && log.timeOut && isSameDay(new Date(log.timeOut), new Date()) && !snackbarsShown.includes("noTimeIn")) {
        if (parseInt(calculateHoursFromDuration(lastTimeOutDurationInMins)) >= 20) {
            dispatch(setShowSnackbar(SnackbarMessages.hours.noTimeIn));
            setSnackbarsShown(snackbarsShown.push("noTimeIn"));
        }
    }

    return (
        <Box className="home-page" sx={{height: '100%'}}>
            <Box className="user-details" sx={{margin: '10px 6% 0'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'column'}}>
                    <Typography variant="span"
                                sx={{
                                    width: '100%',
                                    fontSize: '24px',
                                    fontWeight: '600'
                                }}>{`${firstName} ${lastName}`}</Typography>
                    {
                        companyPost &&
                        <Typography variant="span"
                                    sx={{width: '100%', fontSize: '14px'}}>Post: {companyPost.postName}</Typography>
                    }
                </Box>
                <Clock isClockedIn={hasTimeInEntry} onTick={setHasTicked}/>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <TextField
                        label="Add Note"
                        variant="outlined"
                        sx={{
                            width: '90%',
                            margin: '10px 0 8px 0'
                        }}
                        defaultValue={log.notes}
                        value={log.notes}
                        onChange={noteOnChange}
                        disabled={log.timeOut}
                    />
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                        <Typography sx={{
                            fontSize: 28,
                            fontWeight: 'bold',
                            color: hasTimeInEntry ? '#c0c0c0' : '#030303'
                        }}>IN</Typography>
                        <MaterialUISwitch sx={{m: 1, transform: "scale(1.25)"}} checked={hasTimeInEntry}
                                          onChange={() => sethasTimeInEntry(!hasTimeInEntry)}/>
                        {/*<FormControlLabel*/}
                        {/*    control={}*/}
                        {/*/>*/}
                        <Typography sx={{
                            fontSize: 28,
                            fontWeight: 'bold',
                            color: !hasTimeInEntry ? '#c0c0c0' : '#030303'
                        }}>OUT</Typography>
                    </Box>

                    {
                        // log && log.timeIn && !log.timeOut &&
                        hasTimeInEntry &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <Box>
                                <Select
                                    sx={{marginTop: '20px', marginBottom: '20px', width: '120px'}}
                                    defaultValue={1}
                                    label="Shift"
                                    onChange={(event) => {
                                        console.log("Value", event, event.target.value)
                                        setShiftHours(event.target.value)
                                    }
                                    }
                                >
                                    {renderHoursMenuItem()}
                                </Select>
                            </Box>

                            {
                                log &&
                                <Box sx={{marginTop: '12px', marginBottom: '8px'}}>
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: parseInt(calculateHoursFromDuration(lastTimeInDurationInMins)) >= shiftHours ? 'green' : 'red',
                                            marginRight: '4px'
                                        }}
                                    >
                                        {`${calculateHoursFromDuration(lastTimeInDurationInMins)} ${calculateMinsFromDuration(lastTimeInDurationInMins)}`}
                                    </Typography>
                                    <Typography variant="span">
                                        {`has passed since start of shift`}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    }
                    {
                        // (!log || !log.timeIn) &&
                        // <Button
                        //     variant="contained"
                        //     sx={{
                        //         height: '56px',
                        //         width: '90%',
                        //         display: 'flex',
                        //         flexDirection: 'row',
                        //         alignItems: 'center',
                        //         justifyContent: 'center',
                        //         marginTop: !hasTimeInEntry ? "20px" : ""
                        //     }}
                        //     onClick={!hasTimeInEntry ? clockInHandler : clockOutHandler}
                        // >
                        //     <TimeInIcon sx={{fontSize: '1.5rem'}}/>
                        //     <Typography variant="span">{hasTimeInEntry ? "Clock out" : "Clock in"}</Typography>
                        // </Button>

                    }
                    {/*{*/}
                    {/*    log && log.timeIn && !log.timeOut &&*/}
                    {/*    <Button*/}
                    {/*        variant="contained"*/}
                    {/*        sx={{*/}
                    {/*            height: '56px',*/}
                    {/*            width: '90%',*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'row',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            justifyContent: 'center'*/}
                    {/*        }}*/}
                    {/*        onClick={clockOutHandler}*/}
                    {/*    >*/}
                    {/*        <TimeInIcon sx={{fontSize: '1.5rem'}}/>*/}
                    {/*        <Typography variant="span">Clock Out</Typography>*/}
                    {/*    </Button>*/}
                    {/*}*/}
                    <Slider
                        defaultText="Slide to Submit"
                        onSlideComplete={!hasTimeInEntry ? clockInHandler : clockOutHandler}
                        sliderColor={timelogSubmitted === "success"? "#acffac" : "#1565c0"}
                        status={timelogSubmitted === "default" ? null : timelogSubmitted}
                    />


                </Box>
                {/*<Slider/>*/}
            </Box>
        </Box>
    );
}

export default HomePage;
