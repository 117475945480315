import Box from "@mui/material/Box";
import {Button, Chip, FormControl, FormHelperText, Slider, Typography} from "@mui/material";
import {buildRequestHeaders, formatDate, getDurationInMins} from "../utils";
import {useLocation, useNavigate} from "react-router-dom";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {axiosInstance} from "../config/interceptors";
import React, {useState} from "react";

const UpdateTimeLogPage = () => {
    const {state: {stateLog}} = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [log, setLog] = useState(stateLog);
    const [errors, setErrors] = useState({
        timeIn: null,
        timeOut: null,
    });

    dispatch(setHeaderTitle(`${log?.employee?.firstName} ${log?.employee?.lastName}`))

    const cancelOnClick = () => {
        navigate(-1);
    }

    const saveOnClick = () => {
        if (!validate()) {
            axiosInstance.post('/timesheet/inspector/log/update', {
                ...log
            }, buildRequestHeaders()).then(response => {
                setLog(response.data);
                navigate(-1);
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const validate = () => {
        let hasError = false;

        if (dayjs(log.timeOut).isBefore(dayjs(log.timeIn))) {
            setErrors({
                ...errors,
                timeOut: "Time-out should be after time-in"
            })

            hasError = true;
        } else {
            setErrors({
               ...errors,
               timeOut: null
            });
        }

        if (dayjs(log.timeIn).isAfter(dayjs(log.timeOut))) {
            setErrors({
                ...errors,
                timeIn: "Time-in should be before time-out"
            })

            hasError = true;
        } else {
            setErrors({
                ...errors,
                timeIn: null
            });
        }

        return hasError;
    }

    const startDateOnChange = (event) => {
        log.timeIn = event.$d;
    }

    const endDateOnChange = (event) => {
        log.timeOut = event.$d;
    }

    const getStatusComponent = (log) => {
        const {timeSheetStatus} = log;

        if (!timeSheetStatus) return '';

        let statusName = timeSheetStatus ? timeSheetStatus.name.toLowerCase() : '';
        const getBackgroundColor = () => {
            if (statusName === 'approved') {
                return "#C3F2CB";
            } else if (statusName === 'pending') {
                return "#FAD79C"
            } else if (statusName === 'missing') {
                return '#F49831';
            } else if (statusName === 'rejected') {
                return '#FA9C9C';
            }
        }

        return <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            fontSize: '12px',
            // width: '30%',
            maxWidth: '30%',
        }}>
            {
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    fontSize: '12px',
                }}>
                    <Chip
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            width: '74px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            backgroundColor: getBackgroundColor(),
                            textTransform: 'capitalize',

                        }}
                        variant="span"
                        label={statusName}
                    />
                </Box>
            }
        </Box>
    }

    const isOverTimeFieldShown = () => {
        const durationInMins = getDurationInMins(log.timeIn, log.timeOut);

        return Math.floor(Math.abs(durationInMins - 540) / 60) >= 1;
    }

    const setOverTimeHours = value => {
        setLog({
            ...log,
            overtimeHours: value,
        })
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '65svh',
                width: '95%',
                border: "1px solid #E5E5E5",
                marginBottom: '18px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                marginTop: '20px'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '95%',
                        margin: '16px 0',
                        paddingBottom: '16px',
                        borderBottom: '1px solid rgba(0,0,0,.4)'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontSize: '12px',
                        // width: '20%',
                        maxWidth: '20%',
                    }}>
                        <Typography variant="span" sx={{
                            fontSize: '10px',
                            color: 'rgba(3,3,3,.68)'
                        }}>{`${formatDate(log.timeIn, 'dddd')}`}</Typography>

                        <Typography variant="span" sx={{
                            fontSize: '20px',
                            fontWeight: '600'
                        }}>{`${formatDate(log.timeIn, 'D')}`}</Typography>
                        <Typography variant="span"
                                    sx={{color: 'rgba(3,3,3,.68)', fontSize: '11px'}}>{`${formatDate(log.timeIn, 'MMM YYYY')}`}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        fontSize: '12px',
                        // width: '50%',
                        margin: '0 16px'
                    }}>
                        <Typography variant="span"
                                    sx={{
                                        fontSize: '14px',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}>{`${formatDate(log.timeIn, 'hh:mm a')} - ${formatDate(log.timeOut, 'hh:mm a')}`}</Typography>
                    </Box>
                    {
                        getStatusComponent(log)
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '95%',
                        height: '100%',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '20px',
                                }}
                                size="small"
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '600'
                                    }}
                                >
                                    Start
                                </Typography>
                                <MobileDateTimePicker
                                    value={dayjs(log.timeIn)}
                                    slotProps={{
                                        fontSize: '10px',
                                        textField: {
                                            variant: 'standard',
                                        }
                                    }}
                                    onChange={startDateOnChange}
                                />
                                {
                                    errors.timeIn &&
                                    <FormHelperText
                                        id={`time-in-error-text`}
                                        sx={{
                                            marginLeft: '0',
                                        }}
                                        error
                                    >
                                        {errors.timeIn}
                                    </FormHelperText>
                                }
                            </FormControl>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '20px'
                                }}
                                size="small"
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '600'
                                    }}
                                >
                                    End
                                </Typography>
                                <Box>
                                    <MobileDateTimePicker
                                        defaultValue={log.timeOut ? dayjs(log.timeOut) : dayjs(log.timeIn)}
                                        slotProps={{
                                            textField: {variant: 'standard'}
                                        }}
                                        onChange={endDateOnChange}
                                    />
                                    {
                                        errors.timeOut &&
                                        <FormHelperText
                                            id={`time-out-error-text`}
                                            sx={{
                                                marginLeft: '0',
                                            }}
                                            error
                                        >
                                            {errors.timeOut}
                                        </FormHelperText>
                                    }
                                </Box>
                            </FormControl>
                            {/*{*/}
                            {/*    isOverTimeFieldShown() &&*/}
                            {/*    <Box*/}
                            {/*        sx={{}}*/}
                            {/*    >*/}
                            {/*        <Typography*/}
                            {/*            sx={{*/}
                            {/*                fontWeight: '600'*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Overtime*/}
                            {/*        </Typography>*/}
                            {/*        <Slider*/}
                            {/*            onChange={(event) => {*/}
                            {/*                setOverTimeHours(event.target.value)*/}
                            {/*            }}*/}
                            {/*            defaultValue={log.overtimeHours}*/}
                            {/*            min={0}*/}
                            {/*            max={10}*/}
                            {/*            valueLabelDisplay="auto"*/}
                            {/*        />*/}
                            {/*    </Box>*/}
                            {/*}*/}
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '0',
                                left: '0',
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                    width: '120px',
                                    height: '40px',
                                }}
                                onClick={saveOnClick}
                            >
                                Save
                            </Button>
                            <Button
                                variant="text"
                                sx={{
                                    width: '120px',
                                    height: '40px',
                                    color: 'rgba(0,0,0, .5)'
                                }}
                                onClick={cancelOnClick}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UpdateTimeLogPage;