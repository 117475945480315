import {Typography} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import {
    buildRequestHeaders,
    calculateHoursFromDuration,
    calculateMinsFromDuration,
    formatDate,
    getDurationInMins
} from "../utils";
import {TimesheetStatus} from "../contstants";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {useDispatch, useSelector} from "react-redux";
import {axiosInstance} from "../config/interceptors";
import EventNoteIcon from "@mui/icons-material/EventNote";

function InspectorTimecard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user)

    const [logs, setLogs] = useState();

    useEffect(() => {
        dispatch(setHeaderTitle("Posts"));
        axiosInstance.get(`/timesheet/${user.id}/cluster-logs`, buildRequestHeaders()).then(response => {
            setLogs(response.data);
        }).catch(error => {
            console.error("Something went wrong");
        });
    }, []);

    const getLogsForApprovalCount = (log) => {
        let count = 0;

        if (log && log.employees) {
            log.employees.forEach(employee => {
                count += employee.timeSheetLogs.filter(log => log.timeSheetStatus.name !== TimesheetStatus.APPROVED).length
            });
        }
        return count;
    }

    let renderTimeEntries = (log, employees) => {
        return employees && employees.map(employee => {
            const filteredMap = employee.timeSheetLogs.filter(log => {
                return log.timeSheetStatus.name !== TimesheetStatus.APPROVED
            });

            return filteredMap.map(timesheetLog => {
                const durationInMins = getDurationInMins(timesheetLog.timeIn, timesheetLog.timeOut);
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '12px 14px',
                            borderBottom: "1px solid #E5E5E5",
                            '&:last-child': {
                                borderBottom: '0'
                            }
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            fontSize: '12px',
                            width: '100%'
                        }}>
                            <Typography
                                variant="span"
                                sx={{
                                    color: 'rgba(3,3,3,1)',
                                    fontWeight: '500',
                                    fontSize: '14px'
                                }}
                            >
                                {`${employee.firstName} ${employee.lastName}`}
                            </Typography>
                            <Typography
                                variant="span"
                                sx={{color: 'rgba(3,3,3,.5)'}}
                            >
                                {`#${employee.employeeId}`}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            fontSize: '12px',
                            width: '100%',
                            marginTop: '6px'
                        }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '65%',
                                }}
                            >
                                <EventNoteIcon
                                    sx={{
                                        color: 'rgba(3,3,3,.5)',
                                        marginRight: '4px',
                                    }}
                                />
                                <Typography
                                    variant="span"
                                    sx={{
                                        color: 'rgba(3,3,3,.5)',
                                        fontSize: '14px'
                                    }}
                                >
                                    {`${formatDate(timesheetLog.timeIn, 'MM/DD/YYYY')}`}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '35%'
                                }}
                            >
                                <AccessTimeIcon
                                    sx={{
                                        color: 'rgba(3,3,3,.5)',
                                        marginRight: '4px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: 'rgba(3,3,3,.5)',
                                    }}
                                    variant="span"
                                >
                                    {`${calculateHoursFromDuration(durationInMins)} ${calculateMinsFromDuration(durationInMins)}`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            });
        })
    }

    const viewAllTimeLogs = (log) => {
        dispatch(setHeaderTitle(log?.post?.postName));
        navigate("/inspector/timesheets/post/employees", {state: {log, user}});
    }

    return logs && logs.map(log => {
        const count = getLogsForApprovalCount(log);

        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    border: "1px solid #E5E5E5",
                    marginBottom: '18px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => viewAllTimeLogs(log)}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column-reverse',
                    borderBottom: "1px solid #E5E5E5",
                    padding: '12px 12px 0',
                }}>
                    <Box sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        height: '48px',
                        fontWeight: '600',
                        fontSize: '20px',
                        width: '100%'
                    }}>
                        <Typography variant="span">{`${log?.post?.postName}`}</Typography>
                        <Typography
                            variant="span"
                            sx={{
                                color: 'rgba(3,3,3,.68)',
                                fontSize: '12px'
                            }}
                        >
                            {`${count}`} entries waiting for approval
                        </Typography>
                    </Box>
                </Box>
                {renderTimeEntries(log, log.employees)}
            </Box>
        )
    });
}

export default InspectorTimecard;
