import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {injectStore} from "./config/interceptors";
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './redux/store';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";


const root = ReactDOM.createRoot(document.getElementById('root'));

// Routes.propTypes = {children: PropTypes.node};
injectStore(store);
root.render(
    <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App/>
        </LocalizationProvider>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
