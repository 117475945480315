import Box from "@mui/material/Box";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment, Slide,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";
import {Roles, Styles} from "../contstants";
import {axiosInstance} from "../config/interceptors";
import {buildRequestHeaders, getJwtToken, parseJwt} from "../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const passwordFieldsInitialState = {value: '', error: {valid: true, text: ''}};

const ProfilePage = ({dashboard = false}) => {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState(passwordFieldsInitialState);
    const [confirmPassword, setConfirmPassword] = useState(passwordFieldsInitialState);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [modalData, setModalData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [changePasswordData, setChangePasswordData] = useState({});

    dispatch(setHeaderTitle("Profile"))

    const user = useSelector(state => state.user);

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    }

    const changePasswordHandler = () => {
        setIsModalOpen(true);
        setModalData({
            title: 'Change Password',
            text: 'Are you sure you want to change your password?',
            affirmativeHandler: () => {
                if (newPassword.value !== confirmPassword.value) {
                    setNewPassword({
                        ...newPassword,
                        error: {
                            valid: false,
                            text: 'Passwords must match'
                        }
                    })

                    setConfirmPassword({
                        ...confirmPassword,
                        error: {
                            valid: false,
                            text: 'Passwords must match'
                        }
                    })

                    setIsModalOpen(false);
                } else {
                    setNewPassword({
                        ...newPassword,
                        error: {valid: true, text: ''}
                    });

                    setConfirmPassword({
                        ...confirmPassword,
                        error: {valid: true, text: ''}
                    });

                    axiosInstance.post('/authentication/password/update', {
                        newPassword: newPassword.value,
                        confirmPassword: confirmPassword.value,
                        role: user.role,
                        userId: user.id,
                    }, buildRequestHeaders()).then(response => {
                        setIsModalOpen(false);
                        setConfirmPassword(passwordFieldsInitialState);
                        setNewPassword(passwordFieldsInitialState);
                    }).catch(error => {
                        console.error(error);
                    });
                }
            },
            negativeHandler: () => {
                setIsModalOpen(false);
            }
        })
    }

    const cancelChangePasswordHandler = () => {
        setNewPassword({value: '', error: {valid: true}});
        setConfirmPassword({value: '', error: {valid: true}});
    }

    const getProfileName = () => {
        const token = getJwtToken();
        const decodedToken = parseJwt(token);

        if (decodedToken.role === Roles.APPROVER) {
            return user.companyPost.managerFullName;
        }

        return `${user.firstName} ${user.lastName}`
    }

    const dashboardMarginLeft = `calc(${Styles.DRAWER_WIDTH} + 20px)`

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '12px 14px',
                paddingTop: `${dashboard ? '20px' : ''}`,
                marginLeft: `${dashboard ? dashboardMarginLeft : ''}`,
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '12px',
                    }}
                >
                    <Typography
                        variant="span"
                        sx={{
                            fontSize: '28px',
                            fontWeight: '600',
                        }}
                    >
                        {getProfileName()}
                    </Typography>
                    {
                        user.role === Roles.USER &&
                        <Typography
                            variant="span"
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                            }}
                        >
                            {`Post: ${user.companyPost.postName}`}
                        </Typography>
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: {md: '400px'},
                        marginTop: '40px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontWeight: '600',
                        }}
                    >
                        Change Password
                    </Typography>
                    <TextField
                        autoComplete={false}
                        label="New Password"
                        variant="standard"
                        type={showNewPassword ? "text" : "password"}
                        sx={{
                            width: '95%',
                            margin: '10px 0 8px 0'
                        }}
                        onChange={event => {
                            setNewPassword({
                                ...newPassword,
                                value: event.target.value,
                            });
                        }}
                        value={newPassword.value}
                        error={newPassword.error.valid ? '' : 'error'}
                        helperText={newPassword.error.text}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={toggleNewPasswordVisibility}>
                                    <VisibilityIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        autoComplete={false}
                        label="Confirm Password"
                        variant="standard"
                        type={showConfirmPassword ? "text" : "password"}
                        sx={{
                            width: '95%',
                            margin: '10px 0 8px 0'
                        }}
                        onChange={event => {
                            setConfirmPassword({
                                ...confirmPassword,
                                value: event.target.value,
                            });
                        }}
                        value={confirmPassword.value}
                        error={confirmPassword.error.valid ? '' : 'error'}
                        helperText={confirmPassword.error.text}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={toggleConfirmPasswordVisibility}>
                                    <VisibilityIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                height: '40px',
                                width: '40%',
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={changePasswordHandler}
                        >
                            <Typography variant="span">Submit</Typography>
                        </Button>
                        <Button
                            variant="text"
                            sx={{
                                height: '40px',
                                width: '40%',
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={cancelChangePasswordHandler}
                        >
                            <Typography variant="span">Clear</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={isModalOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setIsModalOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{modalData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {modalData.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={modalData.affirmativeHandler} variant="contained">OK</Button>
                    <Button onClick={modalData.negativeHandler} sx={{
                        color: 'rgba(0, 0, 0, .5)'
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ProfilePage;