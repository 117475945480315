import axios from "axios";
import {showSpinner} from "../redux/action-creators/app";
import {buildRequestHeaders, getJwtToken} from "../utils";

let store

export const injectStore = _store => {
    store = _store
}

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent

        store.dispatch(showSpinner(true))
        return config;
    },
    function (error) {
        console.error("An error was encountered", error)

        // Do something with request error
        return Promise.reject(error).finally(() => store.dispatch(showSpinner(false)));
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        store.dispatch(showSpinner(false))
        return response;
    },
    function (error) {
        console.error("An error was encountered", error)

        return Promise.reject(error).finally(() => store.dispatch(showSpinner(false)));
    }
);
