import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import _ from "underscore";
import {axiosInstance} from "../../../config/interceptors";
import {buildRequestHeaders, convertToDropdownData, formatDate} from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import {useDispatch} from "react-redux";
import {setShowSnackbar} from "../../../redux/action-creators/ui";
import {Constants, Errors, SnackbarMessages, SnackbarTypes} from "../../../contstants";
import {EmployeeFormFields} from "../../../utils/employee-form-fields";
import {useLocation, useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import PendingIcon from "@mui/icons-material/Pending";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";


const textFieldWidth = '300px';
const rowMargin = '14px 0 14px';
const headerMargins = '0 0 18px';
const labelMargin = '8px';
const labelFontWeight = '500';

const formDataInitialState = {
    firstName: null,
    lastName: null,
    middleName: null,
    gender: null,
    email: null,
    startDate: null,
    endDate: null,
    employeeId: null,
    role: null,
    maritalStatus: null,
    jobTitle: null,
    employeeStatus: null,
    salary: null,
    salaryPaymentInfo: null,
    timeSheetLogs: [],
    companyPost: {
        id: null,
        company: {
            id: null,
        },
        cluster: {
            id: null,
        },
    }
}

const columns = [
    {
        field: 'timelogDate',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        width: 220,
        valueFormatter: ({value}) => value && formatDate(value, 'MM/DD/YYYY')
    },
    {
        field: 'loggedHours',
        headerName: 'Logged Hours',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        description: 'This is the automated duration captured by the system.'
    },
    {
        field: 'actualHours',
        headerName: 'Actual Hours',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        description: 'This is the time manually selected by the user upon time-out.'
    },
    {field: 'overtimeHours', headerName: 'Overtime', headerAlign: 'center', align: 'center', width: 180},
    {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        renderCell: (params) => {

            if (params.value === "PENDING") {
                return (
                    <Tooltip title="Pending">
                        <PendingIcon
                            sx={{
                                color: '#ff9a47'
                            }}
                        />
                    </Tooltip>
                )
            }

            if (params.value === "APPROVED") {
                return (
                    <Tooltip title="Approved">
                        <TaskAltIcon
                            sx={{
                                color: '#4d784d'
                            }}
                        />
                    </Tooltip>
                )
            }

            if (params.value === "MISSING") {
                return (
                    <Tooltip title="Missing">
                        <HelpOutlineIcon
                            sx={{
                                color: '#ca1d1b'
                            }}
                        />
                    </Tooltip>
                )
            }

            if (params.value === "REJECTED") {
                return (
                    <Tooltip title="Rejected">
                        <ReportProblemIcon
                            sx={{
                                color: '#ca1d1b'
                            }}
                        />
                    </Tooltip>
                )
            }
        },
    },
];


const EmployeeForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state: {mode, data}} = useLocation();
    const [editOverride, setEditOverride] = useState(mode === Constants.SAVE_MODE)

    let defaultFormData = formDataInitialState;
    if (mode === Constants.EDIT_MODE || mode === Constants.VIEW_MODE) {
        defaultFormData = data;
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});
    const [rowData, setRowData] = useState([]);

    // useEffect for mounting components
    // ran only once
    useEffect(() => {
        axiosInstance.get('/admin/marital-statuses', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    maritalStatus: convertToDropdownData(response.data, 'name', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/employee-statuses', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    employeeStatus: convertToDropdownData(response.data, 'name', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/job-titles', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    jobTitle: convertToDropdownData(response.data, 'name', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/roles/employees', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    role: convertToDropdownData(response.data, 'name', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/posts', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    companyPost: convertToDropdownData(response.data, 'postName', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/banks', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    bank: convertToDropdownData(response.data, 'name', 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });

        let periodStart = dayjs();
        let periodEnd = dayjs();

        if (periodStart.get('date') <= 15) {
            periodStart = periodStart.set('date', 1);
        } else if (periodStart.get('date') >= 16) {
            periodStart = periodStart.set('date', 16);
        }

        if (periodEnd.get('date') <= 15) {
            periodEnd = periodEnd.set('date', 15);
        } else {
            periodEnd = periodEnd.set('date', periodEnd.endOf('month').get('date'));
        }

        axiosInstance.get(`/timesheet/${formData.id}?periodStart=${periodStart.format('MM/DD/YYYY')}&periodEnd=${periodEnd.format('MM/DD/YYYY')}`, buildRequestHeaders())
            .then(response => {
                setRowData(response.data);
            })
            .catch(error => {
                console.error(error);
            });


    }, []);

    // useEffect for whenever the component updates
    useEffect(() => {

    }, []);

    const setEmployeeId = value => setFormData({
        ...formData,
        employeeId: value,
    });

    const setFirstName = value => setFormData({
        ...formData,
        firstName: value,
    });

    const setLastName = value => setFormData({
        ...formData,
        lastName: value,
    });

    const setMiddleName = value => setFormData({
        ...formData,
        middleName: value,
    });

    const setMobileNumber = value => setFormData({
        ...formData,
        mobileNumber: value,
    });

    const setEmail = value => setFormData({
        ...formData,
        email: value,
    });

    const setDateOfBirth = value => setFormData({
        ...formData,
        dateOfBirth: dayjs(value),
    });

    const setGender = value => setFormData({
        ...formData,
        gender: value,
    });

    const setMaritalStatus = value => setFormData({
        ...formData,
        maritalStatus: {id: value},
    });

    const setJobTitle = value => setFormData({
        ...formData,
        jobTitle: {id: value},
    });

    const setRole = value => setFormData({
        ...formData,
        role: {id: value},
    });

    const setEmployeeStatus = value => setFormData({
        ...formData,
        employeeStatus: {id: value},
    });

    const setStartDate = value => setFormData({
        ...formData,
        startDate: value,
    });

    const setEndDate = value => setFormData({
        ...formData,
        endDate: value,
    });

    const setDailyRate = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            dailyRate: value,
        }
    })

    const setSSSNumber = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            sssNumber: value,
        }
    });

    const setPFNumber = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            pfNumber: value,
        }
    });

    const setPhilHealthNumber = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            philHealthNumber: value,
        }
    });

    const setSSSLoanAmount = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            sssLoanAmount: value,
        }
    });

    const setSSSLoanEndDate = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            sssLoanEndDate: dayjs(value),
        }
    });

    const setSSSLoanMonthly = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            sssLoanMonthly: value,
        }
    });

    const setPFLoanAmount = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            pfLoanAmount: value,
        }
    });

    const setPFLoanEndDate = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            pfLoanEndDate: dayjs(value),
        }
    });

    const setPFLoanMonthly = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            pfLoanMonthly: value,
        },
    });

    const setCashBond = value => setFormData({
        ...formData,
        salary: {
            ...formData.salary,
            cashBond: value,
        }
    });

    const setBank = value => setFormData({
        ...formData,
        salaryPaymentInfo: {
            ...formData.salaryPaymentInfo,
            bank: {id: value},
        }
    });

    const setBankAccountNumber = value => setFormData({
        ...formData,
        salaryPaymentInfo: {
            ...formData.salaryPaymentInfo,
            bankAccountNumber: value,
        }
    });

    const setBankBranch = value => setFormData({
        ...formData,
        salaryPaymentInfo: {
            ...formData.salaryPaymentInfo,
            bankBranch: value,
        }
    });

    const setCompanyPost = value => setFormData({
        ...formData,
        companyPost: {id: value}
    });

    const formatHours = value => {
        if (!value) {
            return null;
        }

        let formattedHours = dayjs(value.$d).format("HH:mm:ss");

        if (dayjs(formattedHours).isValid() || formattedHours === 'Invalid Date') {
            return null;
        }

        return formattedHours;
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}

        EmployeeFormFields.forEach(field => {
            if (!field.ignore) {
                let value = _.get(params, field.name);
                if (!_.isEmpty(field.path)) {
                    value = _.get(params, field.path);
                }

                if (field.required) {
                    if (
                        ((_.isNull(value) || _.isUndefined(value)) &&
                            (_.isNull(value?.id) || _.isUndefined(value?.id))) ||
                        (_.isObject(value) && _.isNull(value)) ||
                        (_.isNumber(value) && (value < 0 || _.isNull(value))) ||
                        (_.isString(value) && _.isNull(value))
                    ) {
                        hasError = true
                        errors = {
                            ...errors,
                            [field.name]: {
                                hasError: true,
                                message: Errors.REQUIRED
                            }
                        }
                    }

                    if (!_.isNull(field.format) && !_.isUndefined(field.format)) {
                        if (!value?.match(field.format)) {
                            hasError = true
                            errors = {
                                ...errors,
                                [field.name]: {
                                    hasError: true,
                                    message: Errors.INVALID_VALUE
                                }
                            }
                        }
                    }
                }

            }
        });

        setFormError(errors);
        return hasError;
    }

    const submitOnClick = useCallback(() => {
        let params = {
            ...formData,
        }

        // This should only apply for new setups
        if (params && params.companyPost && params.companyPost.id === null) {
            delete params.companyPost;
        }

        if (!validateForm(params)) {
            let url = '/admin/employee/new';
            if (editOverride && mode !== Constants.SAVE_MODE) {
                url = '/admin/employee/update';

                // Delete unwanted params for updating
                delete params.timeSheetLogs;
            }

            let snackbarData;
            axiosInstance.post(url, {
                ...params
            }, buildRequestHeaders()).then(response => {
                snackbarData = SnackbarMessages.success.save;

                navigate("/admin/dashboard/employees");
            }).catch(error => {
                snackbarData = {
                    message: error?.response?.data,
                    title: "Failed!",
                    type: SnackbarTypes.ERROR,
                }

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarData))
                snackbarData = {};
            });
        } else {
            dispatch(setShowSnackbar({
                message: 'Please check all entries',
                title: 'Failed!',
                type: SnackbarTypes.ERROR,
            }))
        }
    }, [formData, formError])

    const cancelOnClick = () => {
        // Reload muna :D
        setEditOverride(false);
        navigate("/admin/dashboard/employees");
    }

    const getHelperText = (field) => {
        return field && field.message;
    }

    return (
        <Box sx={{
            height: '100%',
            width: '80%',
            marginLeft: '240px',
            marginTop: '40px',
        }}>
            <Box
                sx={{
                    marginLeft: '20px',
                    paddingTop: '20px',
                }}
            >

                <Box>
                    {
                        // Profile Pic here
                    }
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Accordion
                        defaultExpanded={true}
                        sx={{}}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                            sx={{
                                borderBottom: `1px solid rgba(0,0,0,.14)`,
                                '&:before': {
                                    display: 'none',
                                },
                                backgroundColor: 'rgba(67, 67, 67, .8)',
                                color: '#FFFFFF',
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }
                            }}
                        >
                            <Typography

                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Employee Details
                            </Typography>
                            {
                                mode !== Constants.SAVE_MODE &&
                                <Button
                                    sx={{
                                        fontSize: '18px',
                                        color: "#FFFFFF",
                                        '&:hover': {
                                            color: "#d9ba47"
                                        }
                                    }}
                                    onClick={(event) => {
                                        setEditOverride(!editOverride);
                                        event.stopPropagation();
                                    }}
                                >
                                    <EditIcon sx={{marginRight: '4px'}}/>
                                    <Typography
                                        sx={{fontWeight: 'bold'}}>{!editOverride ? 'Edit' : 'Cancel'}</Typography>
                                </Button>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Employee ID
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['employeeId']}
                                        onChange={event => setEmployeeId(event.target.value)}
                                        error={formError['employeeId']}
                                        helperText={getHelperText(formError['employeeId'])}
                                        size="small"
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        First Name
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['firstName']}
                                        onChange={event => setFirstName(event.target.value)}
                                        error={formError['firstName']}
                                        helperText={getHelperText(formError['firstName'])}
                                        size="small"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Middle Name
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['middleName']}
                                        onChange={event => setMiddleName(event.target.value)}
                                        error={formError['middleName']}
                                        helperText={getHelperText(formError['middleName'])}
                                        size="small"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Last Name
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['lastName']}
                                        onChange={event => setLastName(event.target.value)}
                                        error={formError['lastName']}
                                        helperText={getHelperText(formError['lastName'])}
                                        size="small"
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Email
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        type="email"
                                        defaultValue={formData['email']}
                                        onChange={event => setEmail(event.target.value)}
                                        error={formError['email']}
                                        helperText={getHelperText(formError['email'])}
                                        size="small"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Mobile Number
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['mobileNumber']}
                                        onChange={event => setMobileNumber(event.target.value)}
                                        error={formError['mobileNumber']}
                                        helperText={getHelperText(formError['mobileNumber'])}
                                        size="small"
                                    />
                                </Box>
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['dateOfBirth']}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Date of Birth
                                    </Typography>
                                    <DatePicker
                                        readOnly={!editOverride}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['dateOfBirth'] ? dayjs(formData['dateOfBirth']) : null}
                                        onChange={event => event && event.$d && setDateOfBirth(event.$d)}
                                        disableFuture={true}
                                        slotProps={{
                                            textField: {
                                                size: "small"
                                            }
                                        }}
                                    />
                                    <FormHelperText>{getHelperText(formError['dateOfBirth'])}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['gender']}
                                    size="small"
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Gender
                                    </Typography>
                                    <Select
                                        inputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        variant="standard"
                                        sx={{height: '56px', width: '100%'}}
                                        defaultValue={formData['gender']}
                                        onChange={event => setGender(event.target.value)}
                                    >
                                        <MenuItem value="MALE">MALE</MenuItem>
                                        <MenuItem value="FEMALE">FEMALE</MenuItem>
                                    </Select>
                                    <FormHelperText>{getHelperText(formError['gender'])}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['maritalStatus']}
                                    size="small"
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Marital Status
                                    </Typography>
                                    <Select
                                        inputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        variant="standard"
                                        sx={{height: '56px', width: '100%'}}
                                        defaultValue={formData['maritalStatus'] && formData['maritalStatus'].id}
                                        label="Marital Status"
                                        onChange={event => setMaritalStatus(event.target.value)}
                                    >
                                        {
                                            dropdownData['maritalStatus'] && dropdownData['maritalStatus'].map(item => (
                                                <MenuItem key={`dropdown-${item.value}`}
                                                          value={item.id}>{item.description}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{getHelperText(formError['maritalStatus'])}</FormHelperText>
                                </FormControl>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['jobTitle']}
                                    size="small"
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Job Title
                                    </Typography>
                                    <Select
                                        inputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        variant="standard"
                                        sx={{height: '56px', width: '100%'}}
                                        defaultValue={formData['jobTitle'] && formData['jobTitle'].id}
                                        label="Job Title"
                                        onChange={event => setJobTitle(event.target.value)}
                                    >
                                        {
                                            dropdownData['jobTitle'] && dropdownData['jobTitle'].map(item => (
                                                <MenuItem key={`dropdown-${item.value}`}
                                                          value={item.id}>{item.description}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{getHelperText(formError['jobTitle'])}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['role']}
                                    size="small"
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Role
                                    </Typography>
                                    <Select
                                        inputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        variant="standard"
                                        sx={{height: '56px', width: '100%'}}
                                        defaultValue={formData['role'] && formData['role'].id}
                                        label="Role"
                                        onChange={event => setRole(event.target.value)}
                                    >
                                        {
                                            dropdownData['role'] && dropdownData['role'].map(item => (
                                                <MenuItem key={`dropdown-${item.value}`}
                                                          value={item.id}>{item.description}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{getHelperText(formError['role'])}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['employeeStatus']}
                                    size="small"
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Employee Status
                                    </Typography>
                                    <Select
                                        inputProps={{
                                            readOnly: !editOverride,
                                        }}
                                        variant="standard"
                                        sx={{height: '56px', width: '100%'}}
                                        defaultValue={formData && formData.employeeStatus && formData.employeeStatus.id}
                                        label="Employee Status"
                                        onChange={event => setEmployeeStatus(event.target.value)}
                                    >
                                        {
                                            dropdownData['employeeStatus'] && dropdownData['employeeStatus'].map(item => (
                                                <MenuItem key={`dropdown-${item.value}`}
                                                          value={item.id}>{item.description}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{getHelperText(formError['employeeStatus'])}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: rowMargin,
                                }}
                            >
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['startDate']}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        Start Date
                                    </Typography>
                                    <DatePicker
                                        readOnly={!editOverride}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['startDate'] ? dayjs(formData['startDate']) : null}
                                        onChange={event => event && event.$d && setStartDate(event.$d)}
                                        disableFuture={true}
                                        slotProps={{
                                            textField: {
                                                size: "small"
                                            }
                                        }}
                                    />
                                    <FormHelperText>{getHelperText(formError['startDate'])}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginRight: '14px',
                                        width: textFieldWidth
                                    }}
                                    error={formError['endDate']}
                                >
                                    <Typography className="inputFieldLabel"
                                                sx={{fontWeight: labelFontWeight}}>
                                        End Date
                                    </Typography>
                                    <DatePicker
                                        readOnly={!editOverride}
                                        sx={{width: '100%', marginTop: labelMargin}}
                                        variant="outlined"
                                        defaultValue={formData['endDate'] ? dayjs(formData['endDate']) : null}
                                        onChange={event => event && event.$d && setEndDate(event.$d)}
                                        slotProps={{
                                            textField: {
                                                size: "small"
                                            }
                                        }}
                                    />
                                    <FormHelperText>{getHelperText(formError['endDate'])}</FormHelperText>
                                </FormControl>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                            sx={{
                                borderBottom: `1px solid rgba(0,0,0,.14)`,
                                '&:before': {
                                    display: 'none',
                                },
                                backgroundColor: 'rgba(67, 67, 67, .8)',
                                color: '#FFFFFF'
                            }}
                        >
                            <Typography

                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Salary Information
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth,
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Daily Rate
                                </Typography>
                                <TextField
                                    InputProps={{
                                        readOnly: !editOverride,
                                    }}
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    onChange={event => setDailyRate(event.target.value)}
                                    defaultValue={formData && formData.salary && formData.salary.dailyRate}
                                    type="number"
                                    error={formError['dailyRate']}
                                    helperText={getHelperText(formError['dailyRate'])}
                                    size="small"
                                />
                            </Box>
                            <Accordion
                                defaultExpanded={true}
                                sx={{
                                    marginTop: '20px',
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                                    sx={{
                                        borderBottom: `1px solid rgba(0,0,0,.14)`,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        backgroundColor: 'rgba(67, 67, 67, .8)',
                                        color: '#FFFFFF'
                                    }}
                                >
                                    <Typography

                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Contributions
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        margin: rowMargin,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>
                                            SSS Number
                                        </Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            onChange={event => setSSSNumber(event.target.value)}
                                            defaultValue={formData?.salary?.sssNumber}
                                            type="text"
                                            error={formError['sssNumber']}
                                            helperText={getHelperText(formError['sssNumber'])}
                                            size="small"
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>PF Number</Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            onChange={event => setPFNumber(event.target.value)}
                                            defaultValue={formData?.salary?.pfNumber}
                                            type="text"
                                            error={formError['pfNumber']}
                                            helperText={getHelperText(formError['pfNumber'])}
                                            size="small"
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Philhealth Number</Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData?.salary?.philHealthNumber}
                                            onChange={event => setPhilHealthNumber(event.target.value)}
                                            type="text"
                                            error={formError['philHealthNumber']}
                                            helperText={getHelperText(formError['philHealthNumber'])}
                                            size="small"
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    sx={{
                                        borderBottom: `1px solid rgba(0,0,0,.14)`,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        backgroundColor: 'rgba(67, 67, 67, .8)',
                                        color: '#FFFFFF'
                                    }}
                                    expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                                >
                                    <Typography

                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Loan (SSS)
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        margin: rowMargin,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Loan Amount</Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData && formData.salary && formData.salary.sssLoanAmount}
                                            onChange={event => setSSSLoanAmount(event.target.value)}
                                            type="number"
                                            error={formError['sssLoanAmount']}
                                            helperText={getHelperText(formError['sssLoanAmount'])}
                                            size="small"
                                        />
                                    </Box>
                                    <FormControl
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                        error={formError['dailyRate']}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Loan End Date</Typography>
                                        <DatePicker
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData && formData.salary ? dayjs(formData.salary?.sssLoanEndDate) : null}
                                            onChange={event => event && event.$d && setSSSLoanEndDate(event.$d)}
                                            readonly={!editOverride}
                                            disabled={!formData.salary?.sssLoanAmount || formData.salary?.sssLoanAmount <= 0}
                                            slotProps={{
                                                textField: {
                                                    size: "small"
                                                }
                                            }}
                                        />
                                        <FormHelperText>{getHelperText(formError['sssLoanEndDate'])}</FormHelperText>
                                    </FormControl>
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Loan Monthly</Typography>
                                        <TextField
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData && formData.salary && formData.salary?.sssLoanMonthly}
                                            onChange={event => setSSSLoanMonthly(event.target.value)}
                                            readonly={editOverride || (!formData.salary?.sssLoanAmount || formData.salary?.sssLoanAmount <= 0)}
                                            error={formError['sssLoanMonthly']}
                                            helperText={getHelperText(formError['sssLoanMonthly'])}
                                            size="small"
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    sx={{
                                        borderBottom: `1px solid rgba(0,0,0,.14)`,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        backgroundColor: 'rgba(67, 67, 67, .8)',
                                        color: '#FFFFFF'
                                    }}
                                    expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                                >
                                    <Typography

                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Loan
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            margin: rowMargin,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                margin: rowMargin,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    marginRight: '14px',
                                                    width: textFieldWidth
                                                }}
                                            >
                                                <Typography className="inputFieldLabel"
                                                            sx={{fontWeight: labelFontWeight}}>Loan Amount</Typography>
                                                <TextField
                                                    InputProps={{
                                                        readOnly: !editOverride,
                                                    }}
                                                    sx={{width: '100%', marginTop: labelMargin}}
                                                    variant="outlined"
                                                    defaultValue={formData && formData.salary && formData.salary?.pfLoanAmount}
                                                    onChange={event => setPFLoanAmount(event.target.value)}
                                                    error={formError['pfLoanAmount']}
                                                    helperText={getHelperText(formError['pfLoanAmount'])}
                                                    size="small"
                                                />
                                            </Box>
                                            <FormControl
                                                sx={{
                                                    marginRight: '14px',
                                                    width: textFieldWidth
                                                }}
                                                error={formError['pfLoanEndDate']}
                                            >
                                                <Typography className="inputFieldLabel"
                                                            sx={{fontWeight: labelFontWeight}}>Loan End
                                                    Date</Typography>
                                                <DatePicker
                                                    sx={{width: '100%', marginTop: labelMargin}}
                                                    variant="outlined"
                                                    defaultValue={formData && formData.salary ? dayjs(formData.salary?.pfLoanEndDate) : null}
                                                    onChange={event => event && event.$d && setPFLoanEndDate(event.$d)}
                                                    readonly={!editOverride}
                                                    disabled={!formData.salary?.pfLoanAmount || formData.salary?.pfLoanAmount <= 0}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small"
                                                        }
                                                    }}
                                                />
                                                <FormHelperText>{getHelperText(formError['pfLoanEndDate'])}</FormHelperText>
                                            </FormControl>
                                            <Box
                                                sx={{
                                                    marginRight: '14px',
                                                    width: textFieldWidth
                                                }}
                                            >
                                                <Typography className="inputFieldLabel"
                                                            sx={{fontWeight: labelFontWeight}}>Loan Monthly</Typography>
                                                <TextField
                                                    sx={{width: '100%', marginTop: labelMargin}}
                                                    variant="outlined"
                                                    defaultValue={formData && formData.salary && formData.salary?.pfLoanMonthly}
                                                    onChange={event => setPFLoanMonthly(event.target.value)}
                                                    readonly={editOverride || (!formData.salary?.pfLoanAmount || formData.salary?.pfLoanAmount <= 0)}
                                                    error={formError['pfLoanMonthly']}
                                                    helperText={getHelperText(formError['pfLoanMonthly'])}
                                                    size="small"
                                                />
                                            </Box>


                                        </Box>
                                        <Box>
                                            <Box
                                                sx={{
                                                    marginRight: '14px',
                                                    width: textFieldWidth
                                                }}
                                            >
                                                <Typography className="inputFieldLabel"
                                                            sx={{fontWeight: labelFontWeight}}>Cash Bond</Typography>
                                                <TextField
                                                    InputProps={{
                                                        readOnly: !editOverride,
                                                    }}
                                                    sx={{width: '100%', marginTop: labelMargin}}
                                                    variant="outlined"
                                                    defaultValue={formData && formData.salary && formData.salary?.cashBond}
                                                    onChange={event => setCashBond(event.target.value)}
                                                    error={formError['cashBond']}
                                                    helperText={getHelperText(formError['cashBond'])}
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>

                            </Accordion>

                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    sx={{
                                        borderBottom: `1px solid rgba(0,0,0,.14)`,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        backgroundColor: 'rgba(67, 67, 67, .8)',
                                        color: '#FFFFFF'
                                    }}
                                    expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}

                                    >
                                        Salary Payment Info
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        margin: rowMargin,
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                        error={formError['bank']}
                                        size="small"
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>
                                            Bank
                                        </Typography>
                                        <Select
                                            inputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            variant="standard"
                                            sx={{height: '56px', width: '100%'}}
                                            defaultValue={formData && formData.salaryPaymentInfo && formData.salaryPaymentInfo?.bank && formData.salaryPaymentInfo?.bank.id}
                                            label="Bank"
                                            onChange={event => setBank(event.target.value)}
                                        >
                                            {
                                                dropdownData['bank'] && dropdownData['bank'].map(item => (
                                                    <MenuItem key={`dropdown-${item.value}`}
                                                              value={item.id}>{item.description}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText>{getHelperText(formError['bank'])}</FormHelperText>
                                    </FormControl>
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Account Number</Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData && formData.salaryPaymentInfo && formData.salaryPaymentInfo?.bank && formData?.salaryPaymentInfo.bankAccountNumber}
                                            onChange={event => setBankAccountNumber(event.target.value)}
                                            error={formError['bankAccountNumber']}
                                            helperText={getHelperText(formError['bankAccountNumber'])}
                                            size="small"
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            marginRight: '14px',
                                            width: textFieldWidth
                                        }}
                                    >
                                        <Typography className="inputFieldLabel"
                                                    sx={{fontWeight: labelFontWeight}}>Branch</Typography>
                                        <TextField
                                            InputProps={{
                                                readOnly: !editOverride,
                                            }}
                                            sx={{width: '100%', marginTop: labelMargin}}
                                            variant="outlined"
                                            defaultValue={formData && formData.salaryPaymentInfo && formData.salaryPaymentInfo?.bank && formData.salaryPaymentInfo?.bankBranch}
                                            onChange={event => setBankBranch(event.target.value)}
                                            error={formError['bankBranch']}
                                            helperText={getHelperText(formError['bankBranch'])}
                                            size="small"
                                        />
                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                            sx={{
                                borderBottom: `1px solid rgba(0,0,0,.14)`,
                                '&:before': {
                                    display: 'none',
                                },
                                backgroundColor: 'rgba(67, 67, 67, .8)',
                                color: '#FFFFFF'
                            }}
                        >
                            <Typography

                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Assignment
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                                    sx={{
                                        borderBottom: `1px solid rgba(0,0,0,.14)`,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        backgroundColor: 'rgba(67, 67, 67, .8)',
                                        color: '#FFFFFF'
                                    }}
                                >
                                    <Typography

                                        sx={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Post
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            margin: rowMargin,
                                        }}
                                    >
                                        {
                                            <FormControl
                                                sx={{
                                                    marginRight: '14px',
                                                    width: textFieldWidth
                                                }}
                                                error={formError['companyPost']}
                                                size="small"
                                            >
                                                <Typography className="inputFieldLabel"
                                                            sx={{fontWeight: labelFontWeight}}>
                                                    Company Post
                                                </Typography>
                                                <Select
                                                    inputProps={{
                                                        readOnly: !editOverride,
                                                    }}
                                                    variant="standard"
                                                    sx={{height: '56px', width: '100%'}}
                                                    defaultValue={formData && formData.companyPost && formData.companyPost?.id}
                                                    label="Company Post"
                                                    onChange={event => setCompanyPost(event.target.value)}
                                                >
                                                    {
                                                        dropdownData['companyPost'] && dropdownData['companyPost'].map(item => (
                                                            <MenuItem key={`dropdown-${item.value}`}
                                                                      value={item.id}>{item.description}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                <FormHelperText>{getHelperText(formError['companyPost'])}</FormHelperText>
                                            </FormControl>
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                            sx={{
                                borderBottom: `1px solid rgba(0,0,0,.14)`,
                                '&:before': {
                                    display: 'none',
                                },
                                backgroundColor: 'rgba(67, 67, 67, .8)',
                                color: '#FFFFFF'
                            }}
                        >
                            <Typography

                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Time Entries
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataGrid
                                rows={rowData}
                                getRowId={(row) => `${row.id}`}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                }}
                                autoHeight={true}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true
                                    },
                                }}
                                // slots={{
                                //     toolbar: CustomToolbar
                                // }}
                                disableRowSelectionOnClick
                                // experimentalFeatures={{columnGrouping: true}}
                                // columnGroupingModel={[]}
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        whiteSpace: "normal",
                                        lineHeight: "normal"
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        // Forced to use important since overriding inline styles
                                        height: "unset !important"
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        // Forced to use important since overriding inline styles
                                        maxHeight: "168px !important"
                                    }
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>

                </Box>

                {
                    editOverride &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                            margin: rowMargin,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: '175px',
                                marginRight: '14px'
                            }}
                            onClick={submitOnClick}
                        >
                            <Typography
                                sx={{
                                    padding: '8px',
                                    fontSize: '16px',
                                }}
                            >Submit
                            </Typography>
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                width: '175px'
                            }}
                            onClick={cancelOnClick}
                        >
                            <Typography
                                sx={{
                                    padding: '8px',
                                    fontSize: '16px'
                                }}
                            >Cancel
                            </Typography>
                        </Button>
                    </Box>
                }
            </Box>

        </Box>
    )
}

export default EmployeeForm;
