import {configureStore} from '@reduxjs/toolkit';
import appReducer from './reducers/app';
import userReducer from './reducers/user';
import uiReducer from './reducers/ui';

export default configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        ui: uiReducer,
        // user: userReducer,
    }
})

