import {Chip, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import dayjs from "dayjs";
import {buildRequestHeaders} from "../utils";
import {useNavigate} from "react-router-dom";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {axiosInstance} from "../config/interceptors";

function EmployeeTimecard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [logs, setLogs] = useState();

    useEffect(() => {
        dispatch(setHeaderTitle('Timesheet'));

        axiosInstance.get(`/timesheet/${user.id}`, buildRequestHeaders()).then(response => {
            setLogs(response.data);
        }).catch(error => {
            console.error("Something went wrong");
        });
    }, []);


    const formatDate = (date, format) => {
        if (date) {
            return dayjs(date).format(format);
        }

        return '';
    }

    const getApprovalComponent = (status) => {
        if (!status) return '';

        let statusName = status ? status.name.toLowerCase() : '';
        const getBackgroundColor = () => {
            if (statusName === 'approved') {
                return "#C3F2CB";
            } else if (statusName === 'pending') {
                return "#FAD79C"
            } else if (statusName === 'missing') {
                return '#F49831';
            } else if (statusName === 'rejected') {
                return '#FA9C9C';
            }
        }

        return <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            fontSize: '12px',
            width: '30%'
        }}>
            {
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    fontSize: '12px',
                }}>
                    <Chip
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            width: '74px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            backgroundColor: getBackgroundColor(),
                            textTransform: 'capitalize',

                        }}
                        variant="span"
                        label={statusName}
                    />

                </Box>
            }
        </Box>
    }

    let timeLogOnClick = (entry) => {
        // navigate("/timesheet/view-log", {state: {entry}});
    }

    let renderTimeEntries = () => {
        return logs && logs.map(entry => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '12px 14px',
                        borderBottom: "1px solid #E5E5E5",
                        '&:last-child': {
                            borderBottom: '0'
                        }
                    }}
                    onClick={timeLogOnClick(entry)}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontSize: '12px',
                        width: '20%'
                    }}>
                        <Typography variant="span" sx={{color: 'rgba(3,3,3,.68)'}}>{`${formatDate(entry.timeIn, 'dddd')}`}</Typography>

                        <Typography variant="span" sx={{
                            fontSize: '20px',
                            fontWeight: '600'
                        }}>{`${formatDate(entry.timeIn, 'D')}`}</Typography>
                        <Typography variant="span"
                                    sx={{color: 'rgba(3,3,3,.68)', fontSize: '11px'}}>{`${formatDate(entry.timeIn, 'MMM YYYY')}`}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        fontSize: '12px',
                        minWidth: '50%',
                        width: '50%',
                        marginLeft: '10px',
                    }}>
                        <Typography variant="span"
                                    sx={{
                                        fontSize: '16px',
                                        width: '100%'
                                    }}>{`${formatDate(entry.timeIn, 'hh:mm a')} - ${formatDate(entry.timeOut, 'hh:mm a')}`}</Typography>
                    </Box>
                    {
                        getApprovalComponent(entry.timeSheetStatus)
                    }
                </Box>
            )
        })
    }

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            border: "1px solid #E5E5E5",
            marginBottom: '90px',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
        }}>
            {renderTimeEntries()}
        </Box>
    );
}

export default EmployeeTimecard;
