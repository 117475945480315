import {ActionType} from "../action-types";

export const setHeaderTitle = payload =>
    dispatch => {
        dispatch({
            type: ActionType.SET_HEADER_TITLE,
            payload,
        })
    }

export const setShowSnackbar = ({message, title, type}) =>
    dispatch => {
        dispatch({
            type: ActionType.SET_SHOW_SNACKBAR,
            payload: {
                message,
                title,
                type
            },
        })
    }

export const setHideSnackbar = () =>
    dispatch => {
        dispatch({
            type: ActionType.SET_HIDE_SNACKBAR,
        })
    }