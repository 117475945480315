export const Constants = {
    // Form "modes"
    SAVE_MODE: 'create',
    EDIT_MODE: 'edit',
    DELETE_MODE: 'delete',
    VIEW_MODE: 'view'

}

export const Styles = {
    DRAWER_WIDTH: '240px',
    LABEL_FONT_WEIGHT: '500',
    LABEL_MARGIN_TOP: '8px',
}

export const Errors = {
    REQUIRED: 'Required field.',
    INVALID_VALUE: 'Invalid value',

}

export const Roles = {
    ADMIN: 'ADMIN',
    USER: 'USER',
    INSPECTOR: 'INSPECTOR',
    APPROVER: 'APPROVER',
    HR: 'HR',
    PAYROLL: 'PAYROLL'

}

export const TimesheetStatus = {
    APPROVED: 'APPROVED',
    MISSING: 'MISSING',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING'
}

export const ZIndex = {
    100: '100',
}

export const SnackbarTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
}

export const SnackbarMessages = {
    success: {
        save: {
            title: 'Success!',
            message: 'Record successfully saved',
            type: SnackbarTypes.SUCCESS,
        },
        delete: {
            title: 'Success!',
            message: 'Record successfully deleted',
            type: SnackbarTypes.SUCCESS,
        }
    },
    error: {
        save: {
            title: 'Failed!',
            message: 'Unable to save record. Please try again.',
            type: SnackbarTypes.ERROR,
        },
        delete: {
            title: 'Failed!',
            message: 'Unable to delete records. Please try again',
            type: SnackbarTypes.ERROR,
        }
    },
    hours: {
        hasTimeIn: {
            title: 'Alert!',
            message: 'You have already started your shift',
            type: SnackbarTypes.SUCCESS
        },
        hasTimeOut: {
            title: 'Alert!',
            message: 'Missing clock IN at start of your shift',
            type: SnackbarTypes.WARNING
        },
        noTimeIn: {
            title: 'Alert!',
            message: 'You have not clocked IN on start of shift',
            type: SnackbarTypes.WARNING
        },
        noTimeOut: {
            title: 'Alert!',
            message: 'You have not clocked OUT since last shift',
            type: SnackbarTypes.WARNING
        },
    }
}

export const HolidayTypes = [
    {
        name: 'REGULAR',
        value: 'REGULAR',
    },
    {
        name: 'SPECIAL',
        value: 'SPECIAL',
    },
    {
        name: 'SPECIAL NON-WORKING',
        value: 'SPECIAL_NON_WORKING',
    }
];