import React, {useEffect} from "react";
import _ from "underscore";
import {HashRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {Roles} from "./contstants";

import EmployeesDesktopPage from "./pages/desktop/employees/dashboard";
import EmployeeForm from "./pages/desktop/employees/form";
import CompanyDashboard from "./pages/desktop/company-dashboard";
import Spinner from "./components/spinner";
import DashboardLayout from "./components/layouts/admin";
import MobileLayout from "./components/layouts/mobile";
import HomePage from "./pages/home";
import BankDashboard from "./pages/desktop/bank-dashboard";
import EmployeeDashboard from "./pages/desktop/employee-dashboard";
import JobTitleDashboard from "./pages/desktop/job-title-dashboard";
import CompanyPostDashboard from "./pages/desktop/company-post-dashboard";
import ClusterDashboard from "./pages/desktop/cluster-dashboard";
import ViewTimeLogDetailsPage from "./pages/view-timelog-details";
import CompanyPostEmployeesPage from "./pages/company-post-employees";
import {setUser} from "./redux/action-creators/user";
import Timesheets from "./pages/timesheets";
import Attendance from "./pages/attendance";
import PayslipPage from "./pages/payslip-page";
import ProfilePage from "./pages/profile-page";
import ErrorPage from "./pages/error-page";
import DisbursementTODO from "./pages/desktop/disbursement-t-o-d-o";
import AdminUsersDashboard from "./pages/desktop/admin-users-dashboard";
import PayrollDashboard from "./pages/desktop/disbursement-dashboard";
import HolidayDashboard from "./pages/desktop/holiday-dashboard";
import AppSettings from "./pages/desktop/app-settings";
import LoginPage from "./pages/login-page";
import ContributionRange from "./pages/desktop/contribution-range";
import SearchEmployeePage from "./pages/search-employee-page";

import SnackbarAlert from "./components/snackbar-alert";
import ViewAllTimeLogs from "./components/view-all-timelogs";
import UpdateTimeLogPage from "./components/update-timesheet";

import {buildRequestHeaders, getJwtToken, parseJwt} from "./utils";
import {axiosInstance} from "./config/interceptors";

import './App.css';
import EmployeeSearchPage from "./pages/desktop/search-employee";

const AppInitializer = () => {
    console.debug("App Initializer Running ...")
    // const [user, setUser] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = getJwtToken();
        const decodedToken = parseJwt(token);
        let userType = "employee";
        let homePage = "/home";

        const getUser = async () => {
            return await axiosInstance.get(`/user/${userType}/${decodedToken.id}`, buildRequestHeaders())
        }

        if (token) {
            if (decodedToken.role === Roles.APPROVER) {
                userType = "client";
                homePage = "/timesheets";
            } else if (decodedToken.role === Roles.INSPECTOR) {
                userType = "employee";
                homePage = "/timesheets/inspector";
            } else if ([Roles.ADMIN, Roles.HR, Roles.PAYROLL].includes(decodedToken.role)) {
                userType = "dashboard-user";
                homePage = "/admin/"
            }

            let user;
            if (decodedToken && decodedToken.role === Roles.USER) {
                getUser().then(response => {
                    user = response.data;
                    dispatch(setUser(user));
                    // window.location.replace(homePage);
                }).catch(exception => {
                    console.error("User not found!");
                });
            }
        }
    }, []);
}

const App = () => {
    AppInitializer();

    // On app load, check if token exists

    const RequireAuth = ({element, role: roles}) => {
        const navigate = useNavigate();
        const {showSpinner} = useSelector(state => state.app);

        let user = useSelector(state => state.user);

        useEffect(() => {
            if (!showSpinner) {
                if (_.isEmpty(user)) {
                    navigate("/login", {replace: true})
                }

                if (!_.isEmpty(user) && user.role && !roles.includes(user?.role?.name)) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    navigate("/unauthorized", {replace: true});
                }
            }
        }, []);

        console.debug("Page Requires Permission to Load ...", roles, user);
        return element;
    };

    // TODO - Need to move this to redux logout
    const RedirectLogout = () => {
        const navigate = useNavigate();
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login", {replace: true});
        return;
    }

    return (
        <HashRouter>
            <Box>
                <Routes errorElement={<ErrorPage/>}>
                    <Route
                        exact
                        path="/"
                        element={<Navigate to="/login" replace={true}/>}
                    />
                    <Route path={"/login"} Component={LoginPage}/>

                    <Route element={<MobileLayout/>}>
                        <Route path="/timesheets"
                               element={<RequireAuth role={[Roles.USER, Roles.APPROVER, Roles.INSPECTOR]}
                                                     element={<Timesheets/>}/>}/>
                        <Route path="/timesheets/inspector"
                               element={<RequireAuth role={[Roles.INSPECTOR]}
                                                     element={<Timesheets/>}/>}/>
                        <Route path="/profile"
                               element={<RequireAuth
                                   role={[Roles.USER, Roles.APPROVER, Roles.INSPECTOR, Roles.ADMIN, Roles.PAYROLL, Roles.HR]}
                                   element={<ProfilePage/>}/>}/>

                        <Route path="/timesheet/view" element={<RequireAuth role={[Roles.USER, Roles.APPROVER]}
                                                                            element={<ViewTimeLogDetailsPage/>}/>}/>
                        <Route path="/timesheet/view/all" element={<RequireAuth role={[Roles.USER, Roles.APPROVER]}
                                                                                element={<ViewAllTimeLogs/>}/>}/>

                        <Route path="/inspector/search" element={<RequireAuth role={[Roles.INSPECTOR, Roles.APPROVER]}
                                                                              element={<SearchEmployeePage/>}/>}/>

                        <Route path="/search" element={<RequireAuth role={[Roles.INSPECTOR, Roles.APPROVER]}
                                                                    element={<SearchEmployeePage/>}/>}/>

                        <Route path="/home" element={<RequireAuth role={[Roles.USER]} element={<HomePage/>}/>}/>
                        <Route path="/payslip" element={<RequireAuth role={[Roles.USER]} element={<PayslipPage/>}/>}/>

                        <Route path="/inspector/timesheet/log/update"
                               element={<RequireAuth role={[Roles.INSPECTOR]} element={<UpdateTimeLogPage/>}/>}/>
                        <Route path="/inspector/timesheets/post/employees"
                               element={<RequireAuth role={[Roles.INSPECTOR]} element={<CompanyPostEmployeesPage/>}/>}/>
                    </Route>

                    <Route element={<RequireAuth role={[Roles.ADMIN, Roles.HR, Roles.PAYROLL]}
                                                 element={<DashboardLayout/>}/>}>
                        <Route path="/admin">
                            <Route path="employees" Component={EmployeesDesktopPage}/>
                            <Route path="employee">
                                <Route path="new" Component={EmployeeForm}/>
                            </Route>
                            <Route path="dashboard">
                                <Route path="companies" Component={CompanyDashboard}/>
                                <Route path="clusters" Component={ClusterDashboard}/>
                                <Route path="posts" Component={CompanyPostDashboard}/>
                                <Route path="banks" Component={BankDashboard}/>
                                <Route path="job-titles" Component={JobTitleDashboard}/>
                                <Route path="users" Component={AdminUsersDashboard}/>

                                <Route path="employees" Component={EmployeeDashboard}/>
                                <Route path="employees/search" Component={EmployeeSearchPage}/>
                                <Route path="employee" Component={EmployeeForm}/>
                                {/*<Route path="employee-status" Component={EmployeeStatusDashboard}/>*/}
                                <Route path="holidays" Component={HolidayDashboard}/>
                                <Route path="contribution-range" Component={ContributionRange}/>
                                <Route path="profile"
                                       element={<RequireAuth role={[Roles.ADMIN, Roles.PAYROLL, Roles.HR]}
                                                             element={<ProfilePage dashboard={true}/>}/>}/>
                            </Route>
                            <Route path="settings" Component={AppSettings}/>
                        </Route>
                        <Route path="/payroll">
                            <Route path="view" Component={PayrollDashboard}/>
                            <Route path="disbursement" Component={DisbursementTODO}/>
                        </Route>
                    </Route>
                    <Route path="/logout" element={<RedirectLogout/>}/>
                    <Route path="/unauthorized" element={<ErrorPage code={401} error="Unauthorized"/>}/>
                    <Route path="*" element={<ErrorPage code={404} error="Page Not Found"/>}/>
                </Routes>
            </Box>
            <Spinner/>
            <SnackbarAlert/>
        </HashRouter>);
}

export default App;
