import React, {useCallback, useEffect, useState} from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {axiosInstance} from "../../config/interceptors";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {SnackbarMessages, SnackbarTypes} from "../../contstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {buildRequestHeaders, EMAIL_REGEX} from "../../utils";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AlertDialog from "../../components/alert-dialog";


const textFieldWidth = '300px';
const rowMargin = '14px 0 14px';
const headerMargins = '0 0 18px';
const labelMargin = '8px';
const labelFontWeight = '500';

const formDataInitialState = {
    settings: {
        regularHolidayMultiplier: 0,
        specialNonWorkingHolidayMultiplier: 0,
    },
    notificationEmails: []
}

const AppSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let defaultFormData = formDataInitialState;

    const [formData, setFormData] = useState(defaultFormData);
    const [formError, setFormError] = useState({});
    const [addEmail, setAddEmail] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    let snackbarMessage = {};

    // useEffect for mounting components
    // ran only once
    useEffect(() => {
        axiosInstance.get('/admin/app-settings', buildRequestHeaders())
            .then(response => {
                setFormData(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }, []);

    const submitOnClick = useCallback(() => {
        let params = {
            ...formData.settings,
        }

        if (!validateForm(params)) {
            let url = '/admin/app-settings/save';

            axiosInstance.post(url, {
                ...params
            }, buildRequestHeaders()).then(response => {
                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};
            });
        } else {
            dispatch(setShowSnackbar({
                message: 'Please check all entries',
                title: 'Failed!',
                type: SnackbarTypes.ERROR,
            }))
        }
    }, [formData, formError])

    if (_.isEqual(formData, formDataInitialState)) {
        return false;
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}

        setFormError(errors);
        return hasError;
    }

    const cancelOnClick = () => {
        navigate("/admin/");
    }

    const handleUpdateEmail = (email) => {
        setAddEmail(email);
    }

    const handleDeleteEmail = (id) => {
        setIsModalOpen(true);
        setModalData({
            title: 'Delete Notification Email',
            text: 'Are you sure you want to delete this email from the list?',
            affirmativeHandler: () => {
                axiosInstance.delete(`/admin/app-settings/email/${id}`, buildRequestHeaders()).then(response => {
                    setFormData(response.data);
                    snackbarMessage = SnackbarMessages.success.delete;
                }).catch(error => {
                    snackbarMessage = SnackbarMessages.error.delete;
                }).finally(() => {
                    dispatch(setShowSnackbar(snackbarMessage))
                    snackbarMessage = {};
                    setIsModalOpen(false);
                })
            },
            negativeHandler: () => {
                setIsModalOpen(false);
            }
        });


    }

    const handleAddNotificationEmail = () => {
        if (!addEmail || !EMAIL_REGEX.test(addEmail.email)) {
            setFormError({notificationEmail: 'Invalid Email'});
            return;
        } else {
            setFormError({...formError, notificationEmail: null});
        }

        axiosInstance.post(`/admin/app-settings/email`, addEmail, buildRequestHeaders()).then(response => {
            setFormData(response.data);
            snackbarMessage = SnackbarMessages.success.save;
            setAddEmail({id: null, email: ""});
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.save;
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};
        })
    }

    const setNotificationEmail = value => {
        if (value.length === 0) {
            setFormError({
                ...formError,
                notificationEmail: null,
            })
        }
        setAddEmail({
            id: addEmail.id,
            email: value
        });
    }

    const updateFormData = event => {
        setFormData({
            ...formData,
            settings: {
                ...formData.settings,
                [event.target.name]: event.target.value
            }
        })
    }

    // const setEmailNotificationFormData = event => {
    //     setFormData({
    //         ...formData,
    //
    //     })
    // }

    console.log("Form Data", formData)

    return (
        <Box sx={{
            height: '100%',
            width: '80%',
            marginLeft: 'calc(240px + 20px)',
            marginTop: '40px',
            paddingTop: '20px'
        }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Payroll
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Regular Holiday Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="regularHolidayMultiplier"
                                    defaultValue={formData['settings']['regularHolidayMultiplier']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['regularHolidayMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Special Holiday Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="specialNonWorkingHolidayMultiplier"
                                    defaultValue={formData['settings']['specialNonWorkingHolidayMultiplier']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['specialNonWorkingHolidayMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Night Differential Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="nightDifferentialMultiplier"
                                    defaultValue={formData['settings']['nightDifferentialMultiplier']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['nightDifferentialMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Pag-ibig Fund Deduction (Employer)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="pfDeductionEmployer"
                                    defaultValue={formData['settings']['pfDeductionEmployer']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['pfDeductionEmployer']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Pag-ibig Fund Deduction (Employee)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="pfDeductionEmployee"
                                    defaultValue={formData['settings']['pfDeductionEmployee']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['pfDeductionEmployee']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Philhealth Deduction in Percengate (e.g. 0.04)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="phDeductionPercentage"
                                    defaultValue={formData['settings']['phDeductionPercentage']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['phDeductionPercentage']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Attendance
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Days Absent Notice
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="daysAbsentNotice"
                                    defaultValue={formData['settings']['daysAbsentNotice']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['daysAbsentNotice']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Inspector Buffer (Days)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    name="inspectorDaysBuffer"
                                    defaultValue={formData['settings']['inspectorDaysBuffer']}
                                    onChange={event => updateFormData(event)}
                                    error={formError['inspectorDaysBuffer']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                        margin: rowMargin,
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            width: '175px',
                            marginRight: '14px'
                        }}
                        onClick={submitOnClick}
                    >
                        <Typography
                            sx={{
                                padding: '8px',
                                fontSize: '16px',
                            }}
                        >Submit
                        </Typography>
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            width: '175px'
                        }}
                        onClick={cancelOnClick}
                    >
                        <Typography
                            sx={{
                                padding: '8px',
                                fontSize: '16px'
                            }}
                        >Cancel
                        </Typography>
                    </Button>
                </Box>

                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Notifications
                        </Typography>
                    </AccordionSummary>
                    {/*<AccordionDetails>*/}
                    {/*    <Box*/}
                    {/*        sx={{*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            width: '100%',*/}
                    {/*            margin: rowMargin,*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Box*/}
                    {/*            sx={{*/}
                    {/*                marginRight: '14px',*/}
                    {/*                width: '70%',*/}
                    {/*                display: 'flex',*/}
                    {/*                flexDirection: 'row',*/}
                    {/*                alignItems: 'center',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Box sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                flexDirection: 'column',*/}
                    {/*                width: textFieldWidth*/}
                    {/*            }}>*/}
                    {/*                <Typography className="inputFieldLabel"*/}
                    {/*                            sx={{fontWeight: labelFontWeight}}>*/}
                    {/*                    Email*/}
                    {/*                </Typography>*/}
                    {/*                <TextField*/}
                    {/*                    sx={{width: '100%', marginTop: labelMargin}}*/}
                    {/*                    variant="outlined"*/}
                    {/*                    value={addEmail?.email}*/}
                    {/*                    onChange={event => setNotificationEmail(event.target.value)}*/}
                    {/*                    error={formError['notificationEmail']}*/}
                    {/*                    helperText={formError['notificationEmail']}*/}
                    {/*                    size="small"*/}
                    {/*                />*/}
                    {/*            </Box>*/}
                    {/*            <Box sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                alignItems: 'flex-end',*/}
                    {/*                marginLeft: '8px', marginTop: '26px'*/}
                    {/*            }}>*/}
                    {/*                <Button color="primary" startIcon={<AddIcon/>} onClick={handleAddNotificationEmail}>*/}
                    {/*                    Save to Email List*/}
                    {/*                </Button>*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}

                    {/*        <Box sx={{*/}
                    {/*            width: '70%',*/}
                    {/*        }}>*/}
                    {/*            <List>*/}
                    {/*                {*/}
                    {/*                    formData.notificationEmailList?.map(notificationEmail =>*/}
                    {/*                        <ListItem*/}
                    {/*                            secondaryAction={*/}
                    {/*                            <>*/}
                    {/*                                <IconButton edge="end" aria-label="delete"*/}
                    {/*                                            onClick={() => handleUpdateEmail(notificationEmail)}>*/}
                    {/*                                    <EditIcon/>*/}
                    {/*                                </IconButton>*/}
                    {/*                                <IconButton edge="end" aria-label="delete"*/}
                    {/*                                            onClick={() => handleDeleteEmail(notificationEmail.id)}>*/}
                    {/*                                    <DeleteIcon/>*/}
                    {/*                                </IconButton>*/}
                    {/*                            </>*/}
                    {/*                            }*/}
                    {/*                        >*/}
                    {/*                            <ListItemText*/}
                    {/*                                primary={`${notificationEmail.email}`}*/}
                    {/*                                // secondary={secondary ? 'Secondary text' : null}*/}
                    {/*                            />*/}
                    {/*                        </ListItem>*/}
                    {/*                    )*/}
                    {/*                }*/}
                    {/*            </List>*/}
                    {/*        </Box>*/}
                    {/*    </Box>*/}
                    {/*</AccordionDetails>*/}
                </Accordion>
            </Box>

            <AlertDialog
                isOpen={isModalOpen}
                content={modalData.text}
                title={modalData.title}
                confirmCallback={modalData.affirmativeHandler}
                cancelCallback={modalData.negativeHandler}
            />
        </Box>
    )
}

export default AppSettings;
