export const ActionType = {
    // Generic Actions
    SHOW_SPINNER: 'SHOW_SPINNER',

    // User Actions
    SET_USER: 'SET_USER',
    SET_TIME_SHEET_LOGS: 'SET_TIME_SHEET_LOGS',
    SET_USER_TYPE: 'SET_USER_TYPE',

    // UI Actions
    SET_HEADER_TITLE: 'SET_HEADER_TITLE',
    SET_SHOW_SNACKBAR: 'SET_SHOW_SNACKBAR',
    SET_HIDE_SNACKBAR: 'SET_HIDE_SNACKBAR',
    SET_USER_TOKEN: 'SET_USER_TOKEN',

}