import React from "react";
import {Alert, AlertTitle, Slide, Snackbar} from "@mui/material";
import {setHideSnackbar} from "../redux/action-creators/ui";
import {useDispatch, useSelector} from "react-redux";
import "./snackbar-alert.scss";

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

const SnackbarAlert = () => {
    const ui = useSelector(state => state.ui);
    const dispatch = useDispatch();

    const handleSnackbarClose = () => {
        dispatch(setHideSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={ui.snackbar.show}
            autoHideDuration={15000}
            onClose={handleSnackbarClose}
            TransitionComponent={TransitionLeft}
        >
            <Alert variant="filled" onClose={handleSnackbarClose} severity={ui.snackbar.type} sx={{ padding: '10px 20px', width: '350px'}}>
                <AlertTitle sx={{fontWeight: '600'}}>{ui.snackbar.title}</AlertTitle>
                {ui.snackbar.message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarAlert;