import {ActionType} from "../action-types";

export const setUser = user =>
    dispatch => {
        dispatch({
            type: ActionType.SET_USER,
            payload: user,
        })
    }

export const setUserToken = token =>
    dispatch => {
        dispatch({
            type: ActionType.SET_USER_TOKEN,
            payload: token,
        })
    }

export const setUserType = type =>
    dispatch => {
        dispatch({
            type: ActionType.SET_USER_TYPE,
            payload: type,
        })
    }
