import ReportIcon from '@mui/icons-material/Report';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {NavLink} from "react-router-dom";

const ErrorPage = ({code, error}) => {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '40%',
                height: '100vh'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ReportIcon
                    color="error"
                    sx={{
                        fontSize: '120px',
                    }}

                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '36px',
                        fontWeight: '500',
                        color: 'rgba(0, 0, 0, .9)'
                    }}
                >
                    Whoops!
                </Typography>
                <Typography
                    sx={{
                        fontSize: '28px',
                        color: 'rgba(0, 0, 0, .7)'
                    }}
                >
                    Something went wrong
                </Typography>
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: '18px',
                        color: 'rgba(0, 0, 0, .5)'
                    }}
                >
                    {`${error}`}
                </Typography>
                <Typography
                sx={{
                    marginTop: '10px',
                }}
                >
                    {code === 401 && <NavLink to={"/"}>Back to Login</NavLink>}
                </Typography>
            </Box>
        </Box>
    )
}

export default ErrorPage;