import {
    Badge,
    Button,
    Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, Slide,
    TextField,
    Typography
} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import dayjs from "dayjs";
import {
    buildRequestHeaders,
    calculateHoursFromDuration,
    calculateMinsFromDuration,
    formatDate,
    getDurationInMins
} from "../utils";
import {Roles, TimesheetStatus} from "../contstants";
import {useNavigate} from "react-router-dom";
import Duration from "./duration";
import {useEffect, useState} from "react";
import {axiosInstance} from "../config/interceptors";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ApprovalTimecard({user, logsDTO}) {
    const navigate = useNavigate();
    const [logDTOs, setLogDTOs] = useState();
    const [modalData, setModalData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const maxEntryCount = 4;

    useEffect(() => {
        getTimeLogsByCompanyPost();
    }, []);

    const getTimeLogsByCompanyPost = () => {
        axiosInstance.get(`/timesheet/${user.id}/posts`, buildRequestHeaders())
            .then(response => {
                setLogDTOs(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getPendingLogsCount = (logs) => {
        let count = 0;

        if (logs) {
            count = logs.filter(item => item.timeSheetStatus.name === TimesheetStatus.PENDING).length
        }

        return count;
    }

    const getPendingLogsText = (count) => {
        let text = "View";

        if (count > 0) {
            text = `${count} entries waiting for approval`;
        }

        return text;
    }

    const getApprovalComponent = (status) => {

        if (!status) return '';

        let statusName = status ? status.name.toLowerCase() : '';
        const getBackgroundColor = () => {
            if (statusName === 'approved') {
                return "#C3F2CB";
            } else if (statusName === 'pending') {
                return "#FAD79C"
            } else if (statusName === 'missing') {
                return '#F49831';
            } else if (statusName === 'rejected') {
                return '#FA9C9C';
            }
        }

        return <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            fontSize: '12px',
            width: '30%'
        }}>
            {
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    fontSize: '12px',
                }}>
                    <Chip
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            width: '74px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            backgroundColor: getBackgroundColor(),
                            textTransform: 'capitalize',

                        }}
                        variant="span"
                        label={statusName}
                    />

                </Box>
            }
        </Box>
    }

    let timeLogOnClick = (log) => {
        navigate("/timesheet/view", {state: {log}});
    }

    let renderTimeEntries = (entry) => {
        const timeEntries = [];

        entry && entry.logs.forEach((log, index) => {
            const durationInMins = getDurationInMins(log.timeIn, log.timeOut);
            if (timeEntries.length >= maxEntryCount || log.timeSheetStatus.name !== TimesheetStatus.PENDING) {
                return false;
            }

            timeEntries.push(
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '12px 14px',
                        borderBottom: "1px solid #E5E5E5",
                        '&:last-child': {
                            borderBottom: '0'
                        }
                    }}
                    onClick={() => timeLogOnClick(log)}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontSize: '12px',
                        width: '20%',
                    }}>
                        <Typography variant="span" sx={{color: 'rgba(3,3,3,.68)'}}>{`${formatDate(log.timeIn, 'dddd')}`}</Typography>
                        <Typography variant="span" sx={{
                            fontSize: '20px',
                            fontWeight: '600'
                        }}>{`${formatDate(log.timeIn, 'D')}`}</Typography>
                        <Typography variant="span"
                                    sx={{color: 'rgba(3,3,3,.68)', fontSize: '11px'}}>{`${formatDate(log.timeIn, 'MMM YYYY')}`}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        fontSize: '12px',
                        minWidth: '50%',
                        width: '50%',
                        marginLeft: '10px',
                    }}>
                        <Typography variant="span"
                                    sx={{
                                        fontSize: '16px',
                                        width: '100%'
                                    }}>{`${formatDate(log.timeIn, 'hh:mm a')} - ${formatDate(log.timeOut, 'hh:mm a')}`}</Typography>
                    </Box>
                    {
                        user.role.name === Roles.USER && durationInMins &&
                        <Duration
                            durationInMins={durationInMins}
                            styles={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                fontSize: '12px',
                                width: '30%'
                            }}
                        />
                    }
                    {
                        log.timeSheetStatus.name !== TimesheetStatus.PENDING && getApprovalComponent(log.timeSheetStatus)
                    }
                    {
                        log.timeSheetStatus.name === TimesheetStatus.PENDING && durationInMins &&
                        <Duration durationInMins={durationInMins}/>
                    }
                </Box>
            );

            return true;
        })

        return timeEntries;
    }

    const viewAllTimeLogs = (logDTO) => {
        const {logs} = logDTO;
        const employee = {id: logDTO.employeeId};
        const headerTitle = `${logDTO.firstName} ${logDTO.lastName}`;
        navigate("/timesheet/view/all", {state: {logs, employee, user, headerTitle}});
    }

    const approveAll = (logs) => {
        setIsModalOpen(true);

        setModalData({
            title: 'Approve all entries?',
            text: 'Are you sure you want to approve all entries?',
            affirmativeHandler: () => {
                // TODO - TASK#1
                const filteredLogs = logs.filter(log => (log.timeSheetStatus.name === TimesheetStatus.PENDING)).map(log => log.id);

                axiosInstance.put(`/timesheet/approve/multiple/${filteredLogs}`, null, buildRequestHeaders()).then(response => {
                    getTimeLogsByCompanyPost();
                    setIsModalOpen(false);
                    // getAllTimeSheetsByEmployeeId();
                }).catch(error => {
                    console.error(error);
                });
            },
            negativeHandler: () => {
                setIsModalOpen(false)
            }
        });

    }

    const getLastLog = (logs) => {
        const sorted = logs.sort((a, b) => new Date(a.approvalDate) - new Date(b.approvalDate));

        if (sorted) {
            return sorted[sorted.length - 1];
        }

        return null;
    }

    const handleClose = () => {
        setIsModalOpen(false);
    }

    return logDTOs && logDTOs.map(companyPost => {
        const pendingLogsCount = getPendingLogsCount(companyPost.logs);
        const latestLog = getLastLog(companyPost.logs);

        return (
            <Box sx={{
                height: '100%',
                width: '100%',
                border: "1px solid #E5E5E5",
                marginBottom: '18px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column-reverse',
                    borderBottom: "1px solid #E5E5E5",
                    padding: '12px 12px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        height: '48px',
                        fontWeight: '600',
                        fontSize: '20px',
                        width: '100%'
                    }}>
                        <Typography variant="span">{`${companyPost.firstName} ${companyPost.lastName}`}</Typography>
                        {
                            pendingLogsCount === 0 &&
                            <Typography
                                variant="span"
                                sx={{
                                    fontWeight: 'normal',
                                    fontSize: '12px',
                                    color: 'rgba(0, 0, 0, .6)',
                                    marginTop: '6px',
                                }}
                            >
                                {latestLog && `Last approval: ${dayjs(latestLog.approvalDate).format('MM/DD/YYYY hh:mma')}`}
                            </Typography>
                        }
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: '10px',
                            width: '100%'
                        }}>
                        <Typography
                            variant="span"
                            sx={{
                                color: 'rgba(3,3,3,.68)',
                                fontSize: '12px'
                            }}
                            onClick={() => viewAllTimeLogs(companyPost)}

                        >
                            {getPendingLogsText(pendingLogsCount)}
                        </Typography>
                        <KeyboardArrowRightIcon sx={{color: 'rgba(3,3,3,.68)', fontSize: '20px'}}/>
                    </Box>
                </Box>
                {renderTimeEntries(companyPost)}
                {
                    user.role.name === Roles.APPROVER && pendingLogsCount > 0 &&
                    <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', margin: '12px 0'}}>
                        <Button
                            variant="contained"
                            sx={{
                                height: '32px',
                                width: '95%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => approveAll(companyPost.logs)}
                        >
                            Approve All
                        </Button>
                    </Box>
                }
                <Dialog
                    open={isModalOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{modalData.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {modalData.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={modalData.affirmativeHandler} variant="contained">OK</Button>
                        <Button onClick={modalData.negativeHandler} sx={{
                            color: 'rgba(0, 0, 0, .5)'
                        }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    });
}

export default ApprovalTimecard;
