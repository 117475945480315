import {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {axiosInstance} from "../../config/interceptors";
import DashboardComponent from "./dashboard";
import {Constants, Errors, SnackbarTypes} from "../../contstants";
import {buildRequestHeaders, convertToDropdownData} from "../../utils";
import {useNavigate} from "react-router-dom";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {useDispatch} from "react-redux";


const cols = [
    {field: 'lastName', headerName: 'Lastname', width: 150},
    {field: 'firstName', headerName: 'Firstname', width: 150},
    {
        field: 'jobTitle',
        headerName: 'Job Title',
        width: 150,
        valueFormatter: ({value}) => value && value.name
    },
    {field: 'mobileNumber', headerName: 'Mobile Number', width: 150},
    {
        field: 'companyPost',
        headerName: 'Company Post',
        width: 200,
        valueFormatter: ({value}) => value && value.postName
    },
]

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
    },
    {
        name: 'firstName',
        required: true,
        ignore: false,
        label: 'First Name',
        type: 'string'
    },
    {
        name: 'middleName',
        required: true,
        ignore: false,
        label: 'Middle Name',
        type: 'string'
    },
    {
        name: 'lastName',
        required: true,
        ignore: false,
        label: 'Last Name',
        type: 'string'
    },
    {
        name: 'email',
        required: true,
        ignore: false,
        label: 'Email',
        type: 'string'
    },
    {
        name: 'startDate',
        required: true,
        ignore: false,
        label: 'Start Date',
        type: 'string',
        fieldType: 'date'
    },
    {
        name: 'endDate',
        required: true,
        ignore: false,
        label: 'End Date',
        type: 'string',
        fieldType: 'date'
    },
    {
        name: 'authorization',
        required: true,
        ignore: true,
        label: '',
        type: '',
    },
    // {
    //     name: 'designation',
    //     required: true,
    //     ignore: false,
    //     label: 'Designation',
    //     dataName: 'designation',
    //     selectData: [],
    //     type: 'string',
    //     fieldType: 'select',
    // },
    {
        name: 'maritalStatus',
        required: true,
        ignore: false,
        label: 'Marital Status',
        dataName: 'maritalStatus',
        selectData: [],
        type: 'string',
        fieldType: 'select',
    },
    {
        name: 'jobTitle',
        required: true,
        ignore: false,
        label: 'Job Title',
        type: 'string',
        selectData: [],
        dataName: 'jobTitle',
        fieldType: 'select'
    },
    {
        name: 'employeeStatus',
        required: true,
        ignore: false,
        label: 'Employee Status',
        type: 'string',
        selectData: [],
        dataName: 'employeeStatus',
        fieldType: 'select'
    },
    {
        name: 'companyPost',
        required: true,
        ignore: false,
        label: 'Post',
        type: 'string',
        selectData: [],
        dataName: 'companyPost',
        fieldType: 'select'
    },
    {
        name: 'role',
        required: true,
        ignore: false,
        label: 'Role',
        type: 'string',
        selectData: [],
        dataName: 'role',
        fieldType: 'select'
    },
]

const EmployeeDashboard = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Dropdown data
    useEffect(() => {
        axiosInstance.get('/admin/marital-statuses', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, maritalStatus: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/employee-statuses', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, employeeStatus: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/job-titles', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, jobTitle: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/roles/employees', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, role: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/posts', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, companyPost: convertToDropdownData(response.data, 'postName', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const getAllRowData = () => axiosInstance.get('/admin/employees', buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = () => axiosInstance.get('/admin/employee', buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            if (!field.ignore) {
                let value = params[field.name];
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        let params = {
            ...formData,
        }

        if (formMode === Constants.EDIT_MODE) {
            params = {
                ...formData,
            }
        }

        params.jobTitle = {id: params.jobTitle}
        params.maritalStatus = {id: params.maritalStatus}
        params.employeeStatus = {id: params.employeeStatus}
        params.role = {id: params.role}
        params.companyPost = {id: params.companyPost}

        if (!validateForm(params)) {
            axiosInstance.post('/admin/employee/save', {
                ...params
            }, buildRequestHeaders()).then(response => {
                getAllRowData().then(() => {
                    if (callback) {
                        callback();
                    }
                })
            }).catch(error => {
                console.error(error);
            });
        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }

    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`/admin/employee/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);
            dispatch(setShowSnackbar({
                message: 'Record deleted',
                title: 'Success!',
                type: SnackbarTypes.SUCCESS,
            }));

            if (callback) {
                callback();
            }
        }).catch(error => {
            console.error(error);
            dispatch(setShowSnackbar({
                message: 'Something went wrong.',
                title: 'Failed!',
                type: SnackbarTypes.ERROR,
            }));
        });
    }, [selectedRowData])

    const overrides = {
        addButtonOnClick: () => {
            const mode = Constants.SAVE_MODE;
            navigate("/admin/dashboard/employee", {state: {mode}});
        },
        viewButtonOnClick: (data) => {
            const mode = Constants.VIEW_MODE;
            navigate("/admin/dashboard/employee", {state: {mode, data}});
        }
    }

    return (
        <Box>
            <DashboardComponent
                dashboardName="Employee"
                columns={cols}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
                dropdownData={dropdownData}
                overrides={overrides}
            />
        </Box>
    )
}

export default EmployeeDashboard;
