import * as React from 'react';
import {useDispatch} from "react-redux";
import {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField, Typography} from "@mui/material";
import {buildRequestHeaders, formatDate, getDurationInMins} from "../utils";
import Duration from "../components/duration";
import {useLocation, useNavigate} from "react-router-dom";
import {axiosInstance} from "../config/interceptors";
import {Styles, TimesheetStatus} from "../contstants";
import {setHeaderTitle} from "../redux/action-creators/ui";
import DialogContentText from "@mui/material/DialogContentText";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewTimeLogDetailsPage = () => {
    const {state: {log}} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [notes, setNotes] = useState(log.notes);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const durationInMins = getDurationInMins(log.timeIn, log.timeOut);
    const isAlreadyApproved = log.timeSheetStatus.name === TimesheetStatus.APPROVED;

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setHeaderTitle(`${log.employee.firstName} ${log.employee.lastName}`));
    }, []);

    const approveOnClick = () => {
        let notesParam = notes ? `notes=${notes}` : '';

        setIsModalOpen(true);
        setModalData({
            title: 'Approve Time Log Entry',
            text: 'Are you sure you want to approve this entry?',
            affirmativeHandler: () => {
                axiosInstance.put(`/timesheet/approve/${log.id}?${notesParam}`, null, buildRequestHeaders()).then(response => {
                    navigate("/timesheets");
                }).catch(error => {
                    console.error(error);
                });
            },
            negativeHandler: () => {
                setIsModalOpen(false);
            }
        });
    }

    const rejectOnClick = () => {
        setIsModalOpen(true);
        setModalData({
            title: 'Reject Time Log Entry',
            text: 'Are you sure you want to reject this entry?',
            affirmativeHandler: () => {
                axiosInstance.put(`/timesheet/reject/${log.id}`, null, buildRequestHeaders()).then(response => {
                    navigate("/timesheets");
                }).catch(error => {
                    console.error(error);
                });
            },
            negativeHandler: () => {
                setIsModalOpen(false);
            }
        });
    }

    const handleClose = () => {
        setIsModalOpen(false);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: "100%",
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '40%',
                    width: '95%',
                    border: "1px solid #E5E5E5",
                    marginBottom: '18px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                    padding: '32px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "100%",
                        marginBottom: '14px'
                    }}
                >
                    <EventAvailableIcon
                        sx={{
                            fontSize: '60px'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        marginBottom: '8px'

                    }}
                >
                    <Typography
                        variant="span"
                        sx={{
                            fontSize: '14px',
                            width: '100%',
                            color: 'rgba(3, 3, 3, .63)',
                        }}
                    >
                        {`${formatDate(log.timeIn, 'dddd - MMMM D, YYYY')}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        marginBottom: '8px'

                    }}
                >
                    <Typography
                        variant="span"
                        sx={{
                            fontSize: '16px',
                            width: '100%'
                        }}
                    >
                        {`${formatDate(log.timeIn, 'hh:mm a')} - ${formatDate(log.timeOut, 'hh:mm a')}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        marginBottom: '8px'

                    }}
                >
                    <Duration
                        durationInMins={durationInMins}
                        styles={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            fontSize: '12px',
                            width: '100%'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        marginBottom: '8px'

                    }}
                >
                    <TextField
                        id="notes"
                        sx={{width: '100%', marginTop: Styles.LABEL_MARGIN_TOP}}
                        variant="outlined"
                        onChange={event => setNotes(event.target.value)}
                        defaultValue={notes}
                        value={notes}
                        autoComplete='off'
                        placeholder="Add Notes"
                        disabled={isAlreadyApproved}
                    />
                </Box>
                <Box
                    sx={{
                        marginBottom: '8px'

                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            marginRight: '16px'
                        }}
                        onClick={approveOnClick}
                        disabled={isAlreadyApproved}
                    >
                        <Typography
                            sx={{
                                textTransform: "capitalize"
                            }}
                        >
                            Approve
                        </Typography>
                    </Button>
                    <Button
                        color="error"
                        onClick={rejectOnClick}
                    >
                        <Typography
                            sx={{
                                textTransform: "capitalize"
                            }}
                        >
                            Reject
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Dialog
                open={isModalOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{modalData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {modalData.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={modalData.affirmativeHandler} variant="contained">OK</Button>
                    <Button onClick={modalData.negativeHandler} sx={{
                        color: 'rgba(0, 0, 0, .5)'
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ViewTimeLogDetailsPage;