import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../config/interceptors";
import {SnackbarTypes, Styles} from "../../contstants";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers";
import {Button, FormControl, Typography} from "@mui/material";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {setShowSnackbar} from "../../redux/action-creators/ui";

import "./dashboard.scss";
import {buildRequestHeaders} from "../../utils";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {hideSpinner} from "../../redux/action-types/app";


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport/>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

const currencyFormat = new Intl.NumberFormat('en');

const columns = [
    {field: 'firstName', headerName: 'First Name', headerAlign: 'center', align: 'center', width: 180},
    {field: 'middleName', headerName: 'Middle Name', headerAlign: 'center', align: 'center', width: 180},
    {field: 'lastName', headerName: 'Last Name', headerAlign: 'center', align: 'center', width: 180},
    {field: 'jobTitle', headerName: 'Title', headerAlign: 'center', align: 'center', width: 180},
    {
        field: 'dailyRate',
        headerName: 'Daily Rate',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'actualHours',
        headerName: 'Total Hours',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'cutoffDailyRate',
        headerName: 'Cutoff Total Daily Rate',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffSIL',
        headerName: 'SIL',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'overtimeHours',
        headerName: 'OT(H)',
        width: 120,
        align: 'center',
        headerAlign: 'center',
        description: 'Overtime hours'
    },
    {
        field: 'cutoffOvertime',
        headerName: 'OT Pay',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffNightDifferential',
        headerName: 'Night Diff',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'proRated13thMonth',
        headerName: '13th Mo. (Pro rated)',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'prevMonthFullBaseSalary',
        headerName: 'Prev Month Full Base Salary',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cashBond',
        headerName: 'Cash Bond',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'loanSSS',
        headerName: 'Loan SSS',
        description: 'Monthly SSS Loan Payment Amount',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'loanPagIbig',
        headerName: 'Loan Pag-ibig',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffSssEmployeeDeductions',
        headerName: 'SSS',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffPfEmployeeDeductions',
        headerName: 'Pag-ibig Fund',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffPhEmployeeDeductions',
        headerName: 'Phil-health',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffTotalEmployeeDeductions',
        headerName: 'Total',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    // {
    //     field: 'cutoffSssEmployerDeductions',
    //     headerName: 'SSS',
    //     width: 120,
    //     align: 'center',
    //     headerAlign: 'center',
    //     valueFormatter: params =>
    //         params.value?.toFixed(2)
    // },
    // {
    //     field: 'cutoffPfEmployerDeductions',
    //     headerName: 'Pag-ibig Fund',
    //     width: 120,
    //     align: 'center',
    //     headerAlign: 'center',
    //     valueFormatter: params =>
    //         params.value?.toFixed(2)
    // },
    {
        field: 'cutoffPhEmployerDeductions',
        headerName: 'Phil-health',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'cutoffTotalEmployerDeductions',
        headerName: 'Total',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
    {
        field: 'totalSalary',
        headerName: 'Cutoff Total Salary',
        width: 120,
        align: 'right',
        headerAlign: 'center',
        valueFormatter: params =>
            currencyFormat.format(params.value?.toFixed(2))
    },
];


const DisbursementDashboard = () => {
    // Set default payroll schedule
    const currentDay = dayjs();

    let defaultPayStart = currentDay.date(1).hour(0).minute(0).second(0).millisecond(0);
    let defaultPayEnd = currentDay.date(15).hour(0).minute(0).second(0).millisecond(0);

    if (currentDay.date() > 15) {
        defaultPayStart = currentDay.date(16).hour(0).minute(0).second(0).millisecond(0);
        defaultPayEnd = currentDay.date(dayjs().endOf('month').date()).hour(0).minute(0).second(0).millisecond(0);
    }

    const [rowData, setRowData] = useState([]);
    const [periodStart, setPeriodStart] = useState(defaultPayStart);
    const [periodEnd, setPeriodEnd] = useState(defaultPayEnd);
    const [allowPublish, setAllowPublish] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        // getAllRowData();

    }, []);

    const getPayrollByPeriod = () => axiosInstance.get(`/payroll/hours/all?periodStart=${periodStart}&periodEnd=${periodEnd}`, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
            setAllowPublish(true);
        })
        .catch(error => {
            console.error(error);
        });

    const submitOnClick = () => {

        if (dayjs(periodEnd).isBefore(dayjs(periodStart))) {
            dispatch(setShowSnackbar({
                title: "Error!",
                message: "Period start must be before Period end",
                type: SnackbarTypes.ERROR,
            }));

            return;
        }

        getPayrollByPeriod();

    }

    const publishOnClick = () => {
        if (!allowPublish) {
            return;
        }

        axiosInstance.get(`/payroll/publish?periodStart=${periodStart}&periodEnd=${periodEnd}`, buildRequestHeaders())
            .then(response => {
                dispatch(setShowSnackbar({
                    title: "Success!",
                    message: "Payroll has been successfully published",
                    type: SnackbarTypes.SUCCESS,
                }));
                dispatch(hideSpinner());
            })
            .catch(error => {
                dispatch(setShowSnackbar({
                    title: "Error!",
                    message: "Payroll publish failed!",
                    type: SnackbarTypes.ERROR,
                }));
                console.error(error);
            });
    }

    const periodStartOnChange = (event) => {
        let periodStart = dayjs(event.$d).format('MM/DD/YYYY');
        let periodEnd = dayjs(event.$d);
        if (event.$D === 1) {
            periodEnd = periodEnd.set('date', 15).format('MM/DD/YYYY');
        } else if (event.$D === 16) {
            periodEnd = dayjs(event.$d).endOf('month').format('MM/DD/YYYY');
        }

        setPeriodStart(periodStart);
        setPeriodEnd(periodEnd)
    }

    // const periodEndOnChange = (event) => {
    //     setPeriodEnd(dayjs(event.$d).format('MM/DD/YYYY'));
    // }

    const isSubmitDisabled = !periodStart || !periodEnd;

    return (
        <Box>
            <Box sx={{
                height: `100%`,
                width: `calc(100% - ${Styles.DRAWER_WIDTH} - 40px)`,
                display: 'flex',
                flexDirection: 'column',
                marginLeft: `calc(${Styles.DRAWER_WIDTH} + 20px)`,
                '& .cut-off': {
                    backgroundColor: 'rgba(255, 180, 0, 0.55)',
                },
                '& .employee-contributions': {
                    backgroundColor: 'rgba(0, 180, 255, 0.55)',
                },
                '& .employer-contributions': {
                    backgroundColor: 'rgba(255, 7, 0, 0.55)',
                },
            }}>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', width: '100%'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            marginTop: '20px',
                            marginBottom: '10px',
                        }}
                    >
                        <FormControl
                            sx={{
                                marginRight: '14px',
                                width: '600px',
                            }}
                        >
                            <Typography className="inputFieldLabel"
                                        sx={{fontWeight: '500'}}>
                                Period Start (end date is automatically calculated)
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '8px'
                                }}
                            >
                                <DatePicker
                                    sx={{
                                        width: '200px',
                                        marginRight: '8px'
                                    }}
                                    variant="outlined"
                                    onChange={periodStartOnChange}
                                    slotProps={{
                                        textField: {
                                            size: 'small'
                                        }
                                    }}
                                    shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                                    disableFuture={true}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '100px',
                                        height: '40px',
                                    }}
                                    onClick={submitOnClick}
                                    disabled={isSubmitDisabled}
                                >
                                    <Typography
                                        sx={{
                                            padding: '8px',
                                            fontSize: '16px',
                                        }}
                                    >Submit
                                    </Typography>
                                </Button>
                            </Box>
                        </FormControl>
                        <FormControl
                            sx={{
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '140px',
                                        height: '40px',
                                        backgroundColor: '#d2194f'
                                    }}
                                    onClick={publishOnClick}
                                    disabled={!allowPublish}
                                >
                                    <SaveAltIcon sx={{marginRight: '4px'}}/>
                                    <Typography
                                        sx={{
                                            padding: '8px',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Publish
                                    </Typography>
                                </Button>
                            </Box>
                        </FormControl>
                    </Box>
                    <DataGrid
                        rows={rowData}
                        getRowId={(row) => `${row.id}`}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        autoHeight={true}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        disableRowSelectionOnClick
                        experimentalFeatures={{columnGrouping: true}}
                        columnGroupingModel={[
                            {
                                groupId: 'Cut-off',
                                headerClassName: 'cut-off',
                                children: [
                                    {field: 'actualHours'},
                                    {field: 'cutoffDailyRate'},
                                    {field: 'cutoffSIL'},
                                    {field: 'overtimeHours'},
                                    {field: 'cutoffOvertime'},
                                    {field: 'cutoffNightDifferential'},
                                    {field: 'proRated13thMonth'},
                                    {field: 'prevMonthFullBaseSalary'},
                                    {field: 'cashBond'},
                                    {field: 'loanSSS'},
                                    {field: 'loanPagIbig'},
                                    {
                                        groupId: 'Employee Contributions',
                                        headerClassName: 'employee-contributions',
                                        children: [
                                            {field: 'cutoffSssEmployeeDeductions'},
                                            {field: 'cutoffPfEmployeeDeductions'},
                                            {field: 'cutoffPhEmployeeDeductions'},
                                            {field: 'cutoffTotalEmployeeDeductions'},
                                        ]
                                    },
                                    {
                                        groupId: 'Employer Contributions',
                                        headerClassName: 'employer-contributions',
                                        children: [
                                            {field: 'cutoffPhEmployerDeductions'},
                                            {field: 'cutoffTotalEmployerDeductions'},
                                        ]
                                    },
                                ],
                            },
                        ]}
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                whiteSpace: "normal",
                                lineHeight: "normal"
                            },
                            "& .MuiDataGrid-columnHeader": {
                                // Forced to use important since overriding inline styles
                                height: "unset !important"
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // Forced to use important since overriding inline styles
                                maxHeight: "168px !important"
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default DisbursementDashboard;
