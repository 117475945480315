export const EmployeeFormFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        dataType: 'number',
    },
    {
        name: 'employeeId',
        required: true,
        ignore: false,
        label: 'Employee ID',
        dataType: 'string',
        path: ['employeeId']
    },
    {
        name: 'firstName',
        required: true,
        ignore: false,
        label: 'First Name',
        dataType: 'string',
        path: ['firstName']
    },
    {
        name: 'middleName',
        required: false,
        ignore: false,
        label: 'Middle Name',
        dataType: 'string',
        path: ['middleName']
    },
    {
        name: 'lastName',
        required: true,
        ignore: false,
        label: 'Last Name',
        dataType: 'string',
        path: ['lastName']
    },
    {
        name: 'email',
        required: false,
        ignore: false,
        label: 'Email',
        dataType: 'string',
        fieldType: 'email',
        path: ['email'],
        format: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    },
    {
        name: 'dateOfBirth',
        required: true,
        ignore: false,
        label: 'Date of Birth',
        dataType: 'string',
        fieldType: 'date',
        path: ['dateOfBirth']
    },
    {
        name: 'mobileNumber',
        required: false,
        ignore: false,
        label: 'Mobile Number',
        dataType: 'string',
        path: ['mobileNumber']
    },
    {
        name: 'startDate',
        required: true,
        ignore: false,
        label: 'Start Date',
        dataType: 'string',
        fieldType: 'date',
        path: ['startDate']
    },
    {
        name: 'endDate',
        required: false,
        ignore: false,
        label: 'End Date',
        dataType: 'string',
        fieldType: 'date',
        path: ['endDate']
    },
    {
        name: 'authorization',
        required: true,
        ignore: true,
        label: '',
        dataType: '',
    },
    {
        name: 'maritalStatus',
        required: true,
        ignore: false,
        label: 'Marital Status',
        dataName: 'maritalStatus',
        selectData: [],
        dataType: 'string',
        fieldType: 'select',
        path: ['maritalStatus', 'id']
    },
    {
        name: 'gender',
        required: true,
        ignore: false,
        label: 'Gender',
        dataName: 'gender',
        selectData: [],
        dataType: 'string',
        fieldType: 'string',
        path: ['gender']
    },
    {
        name: 'jobTitle',
        required: true,
        ignore: false,
        label: 'Job Title',
        dataType: 'string',
        selectData: [],
        dataName: 'jobTitle',
        fieldType: 'select',
        path: ['jobTitle', 'id']
    },
    {
        name: 'employeeStatus',
        required: true,
        ignore: false,
        label: 'Employee Status',
        dataType: 'string',
        selectData: [],
        dataName: 'employeeStatus',
        fieldType: 'select'
    },
    {
        name: 'companyPost',
        required: false,
        ignore: false,
        label: 'Post',
        dataType: 'string',
        selectData: [],
        dataName: 'companyPost',
        fieldType: 'select'
    },
    {
        name: 'role',
        required: true,
        ignore: false,
        label: 'Role',
        dataType: 'string',
        selectData: [],
        dataName: 'role',
        fieldType: 'select'
    },
    {
        name: 'lastModifiedDate',
        required: false,
        ignore: true,
        label: 'Date Last Modified',
        dataType: 'date',
    },
    {
        name: 'dailyRate',
        required: false,
        ignore: false,
        label: 'Daily Rate',
        dataType: 'number',
        path: ['salary', 'dailyRate']
    },
    {
        name: 'sssNumber',
        required: false,
        ignore: false,
        label: 'SSS Number',
        dataType: 'string'
    },
    {
        name: 'pfNumber',
        required: false,
        ignore: false,
        label: 'PF Number',
        dataType: 'string'
    },
    {
        name: 'philHealthNumber',
        required: false,
        ignore: false,
        label: 'PhilHealth number',
        dataType: 'string'
    },
    {
        name: 'sssLoanAmount',
        required: false,
        ignore: false,
        label: 'Loan Amount',
        dataType: 'number'
    },
    {
        name: 'sssLoanEndDate',
        required: false,
        ignore: false,
        label: 'Loan End Date',
        dataType: 'date',
        requiredField: 'sssLoanAmount',
    },
    {
        name: 'sssLoanMonthly',
        required: false,
        ignore: false,
        label: 'Loan Monthly',
        dataType: 'number',
        requiredField: 'sssLoanAmount',
    },
    {
        name: 'pfLoanAmount',
        required: false,
        ignore: false,
        label: 'Loan Amount',
        dataType: 'number'
    },
    {
        name: 'pfLoanEndDate',
        required: false,
        ignore: false,
        label: 'Loan End Date',
        dataType: 'date',
        requiredField: 'pfLoanAmount'
    },
    {
        name: 'pfLoanMonthly',
        required: false,
        ignore: false,
        label: 'Loan Monthly',
        dataType: 'number',
        requiredField: 'pfLoanAmount'
    },
    {
        name: 'cashBond',
        required: false,
        ignore: false,
        label: 'Cash Bond',
        dataType: 'number'
    },
    {
        name: 'bank',
        required: false,
        ignore: false,
        label: 'Bank',
        dataType: 'string',
        fieldType: 'select',
        path: ['salaryPaymentInfo', 'bank', 'id']
    },
    {
        name: 'bankAccountNumber',
        required: false,
        ignore: false,
        label: 'Account Number',
        dataType: 'number',
        path: ['salaryPaymentInfo', 'bankAccountNumber']
    },
    {
        name: 'bankBranch',
        required: false,
        ignore: false,
        label: 'Branch',
        dataType: 'string',
        path: ['salaryPaymentInfo', 'bankBranch']
    },
    {
        name: 'createdDate',
        required: false,
        ignore: true,
        label: 'Date Created',
        dataType: 'date',
    },
]