import Box from "@mui/material/Box";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import {Typography} from "@mui/material";
import {useState, useEffect} from "react";
import dayjs from "dayjs";

function Clock({isClockedIn, onTick}){
    const [date, setDate] = useState(new Date());
    let iterator = 0;

    function refreshClock() {
        setDate(new Date());
        iterator ++;

        if (iterator === 60) {
            onTick(new Date());
            iterator = 0;
        }
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    const formattedDate = date.toLocaleString('en-PH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour12: true,
    });

    const formattedTime = date.toLocaleString('en-PH', {
        hour: 'numeric',
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    });

    const getTimeIcon = () => {
        const currentTime = dayjs();

        // if (currentTime.hour() > 5 && currentTime.hour() < 18) {
        if (!isClockedIn) {
            return <WbSunnyIcon sx={{
                color: '#000000',
                fontSize: '6rem'
            }}/>
        } else {
            return <BedtimeIcon sx={{
                color: '#000000',
                fontSize: '6rem'
            }}/>
        }
    }



    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '28px'}}>
            <Box>
                {getTimeIcon(isClockedIn)}
            </Box>
            <Box className="time" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Box>
                    <Typography>{formattedDate}</Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '60px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '50px', fontWeight: '600'}}>{formattedTime}</Typography>
                    </Box>
                    {/*<Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>*/}
                    {/*    <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '28px', height: '24px'}}>AM</Typography>*/}
                    {/*    <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '28px', height: '24px'}}>PM</Typography>*/}
                    {/*</Box>*/}
                </Box>
            </Box>
        </Box>
    );
}

export default Clock;