import {FormControl, FormHelperText, TextField, Typography} from "@mui/material";
import {Styles} from "../contstants";

const InputField = ({fieldName, onBlur = ()=>{}, onChange = ()=>{}, defaultValue, label, isRequired = false, error = {}, placeholder = "", disabled = false}) => {
    return (
        <FormControl
            sx={{
                margin: '10px 0',
                width: '100%',
            }}
            required={isRequired}
        >
            <Typography className="inputFieldLabel" variant="span" sx={{fontWeight: Styles.LABEL_FONT_WEIGHT}}>
                {label}
            </Typography>
            <TextField
                id="role-name"
                sx={{width: '100%', marginTop: Styles.LABEL_MARGIN_TOP}}
                variant="outlined"
                onChange={event => onChange(fieldName, event.target.value)}
                onBlur={event => onBlur(fieldName, event.target.value)}
                defaultValue={defaultValue}
                aria-describedby={`${fieldName}-error-text`}
                error={error.hasError}
                autoComplete='off'
                placeholder={placeholder}
                disabled={disabled}
            />
            {
                error.message &&
                <FormHelperText
                    id={`${fieldName}-error-text`}
                    sx={{
                        marginLeft: '0',
                    }}
                    error
                >
                    {error.message}
                </FormHelperText>
            }
        </FormControl>
    )
}

export default InputField;