import _ from "underscore";
import Box from '@mui/material/Box';
import TimeCard from "../components/employee-timecard";
import {connect, useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {axiosInstance} from "../config/interceptors";
import {Roles} from "../contstants";
import ApprovalTimecard from "../components/approval-timecard";
import {setHeaderTitle} from "../redux/action-creators/ui";
import InspectorTimecard from "../components/inspector-timecard";
import {buildRequestHeaders} from "../utils";

function Attendance() {
    const dispatch = useDispatch();
    // const {state: {data}} = useLocation();
    const [logs, setLogs] = useState([]);

    const user = useSelector(state => state.user);

    useEffect(() => {
        if (!_.isEmpty(user) && user.role) {
            if (user.role.name === Roles.USER) {
                dispatch(setHeaderTitle('Timesheet'));
            } else if (user.role.name === Roles.APPROVER) {
                dispatch(setHeaderTitle('Approval'));
            }

            let url = `/timesheet/${user.id}`;
            if (!_.isEmpty(user) && user.role && user.role.name === Roles.APPROVER) {
                url = `/timesheet/${user.id}/posts`;
            }

            axiosInstance.get(url, buildRequestHeaders())
                .then(response => {
                    setLogs([...response.data]);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);

    if (_.isEmpty(user) || !user.role) {
        return null;
    }

    const renderTimeCard = () => {
        const role = user.role.name;
        if (role === Roles.APPROVER) {
            return <ApprovalTimecard
                user={user}
                logsDTO={logs}
            />
        } else if (role === Roles.INSPECTOR) {
            // if ()
            return <InspectorTimecard
                user={user}
            />
        } else if (role === Roles.USER) {
            return <TimeCard
                user={user}
                logsDTO={user.timeSheetLogs}
            />
        }
    }

    const renderCard = () => {
        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: "95%",
            }}>

                {renderTimeCard()}
            </Box>
        )
    }

    return (
        <Box
            sx={{
                height: '100%'
            }}
            className="container"
        >
            <Box
                sx={{
                    display: 'flex', justifyContent: 'center'
                }}
                className="card-wrapper"
            >
                {renderCard()}
            </Box>
        </Box>
    );
}

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(Attendance);
