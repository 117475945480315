import {Outlet} from 'react-router-dom';
import Box from "@mui/material/Box";

import DrawerComponent from "../drawer";

const AdminLayout = () => {

    return (
        <Box
            sx={{
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    height: '100px',
                    width: '100%',
                    backgroundColor: '#1565c0',
                    zIndex: '1001',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        color: '#ffffff',
                        fontSize: '30px',
                        fontWeight: '500',
                        marginLeft: '20px',
                    }}
                >
                    URDUJA
                </Box>
            </Box>
            <DrawerComponent/>
            <Box
                className="content"
                sx={{
                    marginTop: '100px',
                    paddingBottom: '10px'
                }}
            >
                <Outlet/>
            </Box>
        </Box>
    )

}

export default AdminLayout;