import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../config/interceptors";
import {SnackbarTypes, Styles} from "../../contstants";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers";
import {Button, FormControl, Typography} from "@mui/material";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {setShowSnackbar} from "../../redux/action-creators/ui";

import "./dashboard.scss";
import {buildRequestHeaders} from "../../utils";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const columns = [
    {field: 'firstName', headerName: 'First Name', width: 180},
    {field: 'lastName', headerName: 'Last Name', width: 180},
    {
        field: 'hoursApproved',
        headerName: 'Approved(H)',
        type: 'number',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'hoursPending',
        headerName: 'Pending(H)',
        type: 'number',
        width: 120,
        // cellClassName: (params) => `MuiDataGrid-cell--textCenter`,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'hoursLogged',
        headerName: 'Hours Logged',
        description: 'Hours logged by timing-in/out',
        type: 'number',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'actualHours',
        headerName: 'Actual Hours',
        description: 'Actual hours rendered regardless of the duration of time-in/time-out',
        type: 'number',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'bankName',
        headerName: 'Bank',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'bankBranch',
        headerName: 'Branch',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'bankAccount',
        headerName: 'Account #',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'dailyRate',
        headerName: 'Daily Rate',
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'totalDeductions',
        headerName: 'Deductions',
        description: `SSS Contribution + PhilHealth Contribution + SSS Loan Monthly + PhilHealth Loan Monthly + `,
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
];


const DisbursementTODO = () => {
    const [rowData, setRowData] = useState([]);
    const [periodStart, setPeriodStart] = useState();
    const [periodEnd, setPeriodEnd] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        // getAllRowData();

    }, []);

    const getPayrollByPeriod = () => axiosInstance.get(`/payroll/hours/all?periodStart=${periodStart}&periodEnd=${periodEnd}`, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const submitOnClick = () => {

        if (dayjs(periodEnd).isBefore(dayjs(periodStart))) {
            dispatch(setShowSnackbar({
                title: "Error!",
                message: "Period start must be before Period end",
                type: SnackbarTypes.ERROR,
            }));

            return;
        }

        getPayrollByPeriod();

    }

    const periodStartOnChange = (event) => {
        let periodStart = dayjs(event.$d).format('MM/DD/YYYY');
        let periodEnd = dayjs(event.$d);
        console.log("DAY", event.$D)
        if (event.$D === 1) {
            periodEnd = periodEnd.set('date', 15).format('MM/DD/YYYY');
        } else if (event.$D === 16) {
            periodEnd = dayjs(event.$d).endOf('month').format('MM/DD/YYYY');
        }

        console.log("Period End", periodEnd);
        setPeriodStart(periodStart);
        setPeriodEnd(periodEnd)
    }

    const periodEndOnChange = (event) => {
        setPeriodEnd(dayjs(event.$d).format('MM/DD/YYYY'));
    }

    const isSubmitDisabled = !periodStart || !periodEnd

    return (
        <Box>
            <Box sx={{
                height: `100%`,
                width: `calc(100% - ${Styles.DRAWER_WIDTH} - 40px)`,
                display: 'flex',
                flexDirection: 'column',
                marginLeft: `calc(${Styles.DRAWER_WIDTH} + 20px)`,
            }}>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', width: '100%'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            marginTop: '20px',
                            marginBottom: '10px',
                        }}
                    >
                        <FormControl
                            sx={{
                                marginRight: '14px',
                                width: '400px',
                            }}
                        >
                            <Typography className="inputFieldLabel"
                                        sx={{fontWeight: '500'}}>
                                Period Start (end date is automatically calculated)
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '8px'
                                }}
                            >
                                <DatePicker
                                    sx={{
                                        width: '200px',
                                        marginRight: '8px'
                                    }}
                                    variant="outlined"
                                    onChange={periodStartOnChange}
                                    slotProps={{
                                        textField: {
                                            size: 'small'
                                        }
                                    }}
                                    shouldDisableDate={day => day.$D !== 1 && day.$D !== 16}
                                    disableFuture={true}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '100px',
                                        height: '40px',
                                    }}
                                    onClick={submitOnClick}
                                    disabled={isSubmitDisabled}
                                >
                                    <Typography
                                        sx={{
                                            padding: '8px',
                                            fontSize: '16px',
                                        }}
                                    >Submit
                                    </Typography>
                                </Button>
                            </Box>
                        </FormControl>

                        {/*<FormControl*/}
                        {/*    sx={{*/}
                        {/*        marginRight: '14px',*/}
                        {/*        width: '200px'*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Typography className="inputFieldLabel"*/}
                        {/*                sx={{fontWeight: '500'}}>*/}
                        {/*        Period End*/}
                        {/*    </Typography>*/}
                        {/*    <DatePicker*/}
                        {/*        sx={{}}*/}
                        {/*        variant="outlined"*/}
                        {/*        onChange={periodEndOnChange}*/}
                        {/*        slotProps={{*/}
                        {/*            textField: {*/}
                        {/*                size: 'small'*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        disableFuture*/}
                        {/*    />*/}
                        {/*</FormControl>*/}


                    </Box>

                    <DataGrid
                        rows={rowData}
                        getRowId={(row) => `${row.firstName}-${row.lastName}`}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        autoHeight={true}
                        // slots={{
                        //     toolbar: EditToolbar,
                        // }}
                        slotProps={{
                            toolbar: {},
                        }}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        disableRowSelectionOnClick
                    />
                </Box>

                {/*<AlertDialog*/}
                {/*    isOpen={showDeleteModal}*/}
                {/*    text="Are you sure you want to permanently delete these records?"*/}
                {/*    header="Delete Records"*/}
                {/*    confirmCallback={handleDelete}*/}
                {/*    cancelCallback={handleCloseDeleteModal}*/}
                {/*/>*/}
            </Box>
        </Box>
    )
}

export default DisbursementTODO;
