import Box from "@mui/material/Box";
import _ from "underscore";
import LinkIcon from "./link-icon";
import {useSelector} from "react-redux";
import {Roles, ZIndex} from "../contstants";
import {useLocation} from "react-router-dom";

const clientApprovalLinkURLs = [
    "/timesheets",
    "/timesheet/view",
    "/timesheet/view/all"
];

const clientSearchLinkURLs = [
    "/search",
];

// const inspectorTimesheetLinkURLs = [
//     "/timesheets"
// ]

const inspectorPostsLinkURLs = [
    "/timesheets/inspector",
    "/inspector/timesheets/post/employees",
    "/timesheet/view/all",
    "/inspector/timesheet/log/update"
];

const inspectorSearchLinkURLs = [
    "/search",
];

const userTimesheetLinkURLs = [
    "/timesheets",
];

const userAttendanceLinkURLs = [
    "/home"
]

const userPayslipLinkURLs = [
    "/payslip"
]

const userProfileLinkURLs = [
    "/profile"
]

const inspectorProfileLinkURLs = [
    "/profile"
]

const clientProfileLinkURLs = [
    "/profile"
]

function Controls() {
    const user = useSelector(state => state.user);
    const location = useLocation();

    const isLinkActive = (urls) => {
        return urls.includes(location.pathname);
    }

    if (_.isEmpty(user) || !user.role.name) {
        return null;
    }

    if (user.role.name === Roles.APPROVER) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '80px',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    backgroundColor: "#fff",
                    boxShadow: '0px -4px 10px 2px rgba(0, 0, 0, 0.1)',
                    zIndex: ZIndex["100"],
                }}>
                <LinkIcon type="search" isActive={isLinkActive(clientSearchLinkURLs)}/>
                <LinkIcon type="approval" isActive={isLinkActive(clientApprovalLinkURLs)}/>
                <LinkIcon type="profile" isActive={isLinkActive(clientProfileLinkURLs)}/>
            </Box>
        )
    } else if (user.role.name === Roles.USER) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '80px',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    backgroundColor: "#fff",
                    boxShadow: '0px -4px 10px 2px rgba(0, 0, 0, 0.1)',
                    zIndex: ZIndex["100"],
                }}>
                <LinkIcon type="timesheet" isActive={isLinkActive(userTimesheetLinkURLs)}/>
                <LinkIcon type="attendance" isActive={isLinkActive(userAttendanceLinkURLs)}/>
                <LinkIcon type="payslip" isActive={isLinkActive(userPayslipLinkURLs)}/>
                <LinkIcon type="profile" isActive={isLinkActive(userProfileLinkURLs)}/>
            </Box>
        )
    } else if (user.role.name === Roles.INSPECTOR) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '80px',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    backgroundColor: "#fff",
                    boxShadow: '0px -4px 10px 2px rgba(0, 0, 0, 0.1)',
                    zIndex: ZIndex["100"],
                }}>
                <LinkIcon type="attendance" isActive={isLinkActive(userAttendanceLinkURLs)}/>
                <LinkIcon type="timesheet" isActive={isLinkActive(userTimesheetLinkURLs)}/>
                <LinkIcon type="search" isActive={isLinkActive(inspectorSearchLinkURLs)}/>
                <LinkIcon type="posts" isActive={isLinkActive(inspectorPostsLinkURLs)}/>
                <LinkIcon type="profile" isActive={isLinkActive(inspectorProfileLinkURLs)}/>
            </Box>
        )
    }

    return null;
}

export default Controls;