import * as React from "react";
import {useEffect, useState} from "react";
import {Badge, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import {buildRequestHeaders, formatDate, getDurationInMins} from "../utils";
import {Roles, TimesheetStatus} from "../contstants";
import {useLocation, useNavigate} from "react-router-dom";
import Duration from "./duration";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {useDispatch, useSelector} from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {axiosInstance} from "../config/interceptors";
import DialogContentText from "@mui/material/DialogContentText";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewAllTimeLogs() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state: {employee, headerTitle}} = useLocation();
    const [currentLogs, setCurrentLogs] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const user = useSelector(state => state.user)

    useEffect(() => {
        dispatch(setHeaderTitle(headerTitle));

        getAllTimeSheetsByEmployeeId();
    }, []);

    const getAllTimeSheetsByEmployeeId = () => {
        axiosInstance.get(`/timesheet/${employee.id}`, buildRequestHeaders()).then(response => {
            setCurrentLogs(response.data);
        }).catch(error => {
            console.error("Something went wrong");
        });
    }

    const updateTimeLogOnClick = (log) => {
        navigate("/inspector/timesheet/log/update", {state: {stateLog: log}});
    }

    const getOverTime = (durationInMins) => {
        if (durationInMins < 540) {
            return 0;
        }

        const remainderInHours = Math.abs(540 - durationInMins) / 60;

        return Math.floor(remainderInHours);
    }

    const getStatusComponent = (log, durationInMins) => {
        const {timeSheetStatus} = log;

        if (!timeSheetStatus) return '';

        let statusName = timeSheetStatus ? timeSheetStatus.name.toLowerCase() : '';
        const getBackgroundColor = () => {
            if (statusName === 'approved') {
                return "#C3F2CB";
            } else if (statusName === 'pending') {
                return "#FAD79C"
            } else if (statusName === 'missing') {
                return '#F49831';
            } else if (statusName === 'rejected') {
                return '#FA9C9C';
            }
        }

        const isInspectorControlVisible = user?.role?.name !== Roles.APPROVER && (timeSheetStatus?.name === TimesheetStatus.MISSING || timeSheetStatus?.name === TimesheetStatus.REJECTED);

        if (user?.role?.name === Roles.INSPECTOR || user?.role?.name === Roles.USER || (timeSheetStatus?.name !== TimesheetStatus.PENDING && user?.role?.name === Roles.APPROVER)) {
            return <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                fontSize: '12px',
                width: '30%',
                maxWidth: '30%',
            }}>
                {
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        fontSize: '12px',
                    }}>
                        <Chip
                            sx={{
                                fontSize: '10px',
                                fontWeight: '500',
                                width: '74px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                backgroundColor: getBackgroundColor(),
                                textTransform: 'capitalize',
                                "& .MuiChip-label": {
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                }
                            }}
                            variant="span"
                            label={statusName}
                        />
                        <KeyboardArrowRightIcon
                            onClick={() => updateTimeLogOnClick(log)}
                            sx={{
                                color: 'rgba(3,3,3,.68)',
                                fontSize: '20px',
                                width: '20px',
                                height: '20px',
                                visibility: isInspectorControlVisible ? 'visible' : 'hidden',
                            }}
                        />
                    </Box>
                }
            </Box>
        }

        if (user?.role?.name === Roles.APPROVER && timeSheetStatus?.name === TimesheetStatus.PENDING && durationInMins) {
            return <Duration durationInMins={durationInMins} showArrow={true}/>
        }
    }

    let timeLogOnClick = (log) => {
        if (user?.role?.name !== Roles.INSPECTOR) {
            navigate("/timesheet/view", {state: {log}});
        }
    }

    let renderTimeEntries = () => {
        const timeEntries = [];

        if (!currentLogs || currentLogs.length <= 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '12px 14px',
                        borderBottom: "1px solid #E5E5E5",
                        '&:last-child': {
                            borderBottom: '0'
                        }
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%%',
                    }}>
                        <Typography
                            variant="span"
                            sx={{
                                fontSize: '10px',
                                color: 'rgba(3,3,3,.68)'
                            }}
                        >
                            No Logs
                        </Typography>
                    </Box>
                </Box>
            )
        }

        currentLogs && currentLogs.forEach((log, index) => {
            const durationInMins = getDurationInMins(log.timeIn, log.timeOut);
            const overTimeHours = getOverTime(durationInMins);
            // if (index >= maxEntryCount) {
            //     return false;
            // }

            timeEntries.push(
                <Box
                    key={`view-all-timelogs-${index}`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '12px 14px',
                        borderBottom: "1px solid #E5E5E5",
                        '&:last-child': {
                            borderBottom: '0'
                        }
                    }}
                    onClick={() => timeLogOnClick(log)}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontSize: '12px',
                        width: '20%',
                        maxWidth: '52px',
                        minWidth: '52px',
                    }}>
                        <Typography variant="span" sx={{
                            fontSize: '10px',
                            color: 'rgba(3,3,3,.68)'
                        }}>{`${formatDate(log.timeIn, 'dddd')}`}</Typography>

                        <Typography variant="span" sx={{
                            fontSize: '20px',
                            fontWeight: '600'
                        }}>{`${formatDate(log.timeIn, 'D')}`}</Typography>
                        <Typography variant="span"
                                    sx={{color: 'rgba(3,3,3,.68)', fontSize: '11px'}}>{`${formatDate(log.timeIn, 'MMM YYYY')}`}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        fontSize: '12px',
                        minWidth: '50%',
                        width: '50%',
                        marginLeft: '10px',
                    }}>
                        <Badge
                            badgeContent={`+${overTimeHours}`}
                            // badgeContent="OT"
                            color="warning"
                            sx={{
                                "& .MuiBadge-badge": {
                                    right: '-6px',
                                    top: '-2px',
                                    border: `2px solid #fff`,
                                    padding: '0 4px',
                                    height: '26px',
                                    borderRadius: '50%',
                                    // fontSize: "12px",
                                },
                            }}
                            invisible={overTimeHours === 0 || Number.isNaN(overTimeHours)}
                        >
                            <Typography
                                variant="span"
                                sx={{
                                    fontSize: '14px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                {`${formatDate(log.timeIn, 'hh:mm a')} - ${formatDate(log.timeOut, 'hh:mm a')}`}
                            </Typography>
                        </Badge>
                    </Box>
                    {
                        user?.role?.name === Roles.USER && durationInMins &&
                        <Duration
                            durationInMins={durationInMins}
                            styles={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                fontSize: '12px',
                                width: '30%'
                            }}
                        />
                    }
                    {
                        getStatusComponent(log, durationInMins)
                    }
                </Box>
            );

            return true;
        })

        return timeEntries;
    }

    const approveAll = () => {
        setIsModalOpen(true);

        setModalData({
            title: 'Approve all entries?',
            text: 'Are you sure you want to approve all entries?',
            affirmativeHandler: () => {
                // TODO - TASK#1
                const filteredLogs = currentLogs.filter(log => (log.timeSheetStatus.name === TimesheetStatus.PENDING)).map(log => log.id);

                axiosInstance.put(`/timesheet/approve/multiple/${filteredLogs}`, null, buildRequestHeaders()).then(response => {
                    getAllTimeSheetsByEmployeeId();
                }).catch(error => {
                    console.error(error);
                });
            },
            negativeHandler: () => {
                setIsModalOpen(false);
            }
        })
    }

    const getPendingLogsCount = () => {
        let count = 0;

        if (currentLogs) {
            count = currentLogs.filter(item => item.timeSheetStatus.name === TimesheetStatus.PENDING).length
        }

        return count;
    }

    const handleClose = () => {
        setIsModalOpen(false);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }}>
            <Box sx={{
                height: '100%',
                width: '95%',
                border: "1px solid #E5E5E5",
                marginBottom: '18px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                // marginTop: '20px'
            }}>
                {renderTimeEntries()}
                {
                    user?.role?.name === Roles.APPROVER && getPendingLogsCount() > 0 &&
                    <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', margin: '12px 0'}}>
                        <Button
                            variant="contained"
                            sx={{
                                height: '32px',
                                width: '95%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={approveAll}
                        >
                            Approve All
                        </Button>
                    </Box>
                }
                <Dialog
                    open={isModalOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{modalData.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {modalData.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={modalData.affirmativeHandler} variant="contained">OK</Button>
                        <Button onClick={modalData.negativeHandler} sx={{
                            color: 'rgba(0, 0, 0, .5)'
                        }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default ViewAllTimeLogs;
