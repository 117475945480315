import Box from "@mui/material/Box";
import {useLocation, useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";
import {calculateHoursFromDuration, calculateMinsFromDuration, formatDate, getDurationInMins} from "../utils";
import {Roles} from "../contstants";
import Duration from "../components/duration";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {useDispatch} from "react-redux";
import {setHeaderTitle} from "../redux/action-creators/ui";
import dayjs from "dayjs";
import * as React from "react";

const CompanyPostEmployeesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state: {log, user}} = useLocation();

    dispatch(setHeaderTitle(log.post.postName));

    const viewEmployeeLogs = (employee) => {
        navigate("/timesheet/view/all", {
            state: {
                user,
                employee,
                headerTitle: `${employee.firstName} ${employee.lastName}`
            }
        });
    }

    const getLastLog = (logs) => {
        const sorted = logs.sort((a, b) => new Date(a.approvalDate) - new Date(b.approvalDate));

        if (sorted) {
            return sorted[sorted.length - 1];
        }

        return null;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }}>
            <Box sx={{
                height: '100%',
                width: '95%',
                border: "1px solid #E5E5E5",
                marginBottom: '18px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                // marginTop: '20px'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        flexDirection: 'column',
                        padding: '12px 0',
                        borderBottom: "1px solid #E5E5E5",
                        '&:last-child': {
                            borderBottom: '0'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            // alignItems: 'center',
                            flexDirection: 'row',
                            padding: '12px 14px',
                            borderBottom: "1px solid #E5E5E5",
                        }}
                    >
                        {`Assigned Security Guards (${log.employees.length})`}
                    </Box>
                    {
                        log && log.employees && log.employees.map(employee => {
                            const lastLog = getLastLog(employee.timeSheetLogs);
                            const durationInMins = lastLog && getDurationInMins(lastLog.timeIn, lastLog.timeOut);
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        padding: '12px 14px',
                                        borderBottom: "1px solid #E5E5E5",
                                        '&:last-child': {
                                            borderBottom: '0'
                                        }
                                    }}
                                    onClick={() => viewEmployeeLogs(employee)}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                            fontSize: '12px',
                                            width: '100%'
                                        }}
                                    >
                                        <Typography
                                            variant="span"
                                            sx={{
                                                color: 'rgba(3,3,3,1)',
                                                fontWeight: '500',
                                                fontSize: '14px'
                                            }}
                                        >
                                            {`${employee.firstName} ${employee.lastName}`}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            sx={{color: 'rgba(3,3,3,.5)'}}
                                        >
                                            {`#${employee.employeeId}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        fontSize: '12px',
                                        width: '100%',
                                        marginTop: '6px'
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '60%',
                                            }}
                                        >
                                            <EventNoteIcon
                                                sx={{
                                                    color: 'rgba(3,3,3,.5)',
                                                    marginRight: '4px',
                                                }}
                                            />
                                            <Typography
                                                variant="span"
                                                sx={{
                                                    color: 'rgba(3,3,3,.5)',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {`${lastLog && lastLog.createDate ? dayjs(lastLog.createDate).format('MM/DD/YYYY') : ''}`}
                                            </Typography>
                                        </Box>
                                        <Duration
                                            durationInMins={durationInMins}
                                            styles={{
                                                width: '35%',
                                                color: 'rgba(3,3,3,.5)',
                                                fontSize: '12px'
                                            }}
                                            textStyle={{
                                                fontSize: '14px'
                                            }}
                                        />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default CompanyPostEmployeesPage;