import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {setHeaderTitle} from "../redux/action-creators/ui";
import {InputAdornment, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {axiosInstance} from "../config/interceptors";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import {Roles} from "../contstants";
import {buildRequestHeaders} from "../utils";

const SearchEmployeePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState();
    const [logDTOs, setLogDTOs] = useState();

    window.scrollTo(0, 0);

    const user = useSelector(state => state.user);

    dispatch(setHeaderTitle("Search"));

    const getTimeLogsByCompanyPost = () => {
        // By default, URL is for APPROVER
        let url = `/timesheet/${user.id}/posts`;
        if (user.role.name === Roles.INSPECTOR) {
            url = `/timesheet/${user.id}/cluster-logs`
        }

        axiosInstance.get(url, buildRequestHeaders())
            .then(response => {
                const employeeSet = new Set();
                const {data} = response;
                setSearchResults(data);
                setLogDTOs(data);

                if (user.role.name === Roles.INSPECTOR) {
                    data.map(companyPost => {
                        companyPost.employees.map(employee => {
                            if (employee.role.name !== Roles.INSPECTOR) {
                                employeeSet.add(employee);
                            }
                        })
                    });

                    setSearchResults(Array.from(employeeSet));
                    setLogDTOs(Array.from(employeeSet));
                }


            })
            .catch(error => {
                console.error(error);
            });
    }

    const searchEmployees = (searchText) => {
        const regExp = new RegExp(searchText, 'gi');

        setSearchResults(logDTOs.filter(dto => dto.firstName.match(regExp) || dto.lastName.match(regExp)));
    }

    const renderSearchResults = () => {
        return searchResults && searchResults.map(result => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            padding: '12px 14px',
                            borderBottom: "1px solid #E5E5E5",
                            width: '100%',
                            '&:last-child': {
                                borderBottom: '0'
                            }
                        }}
                        onClick={() => {
                            navigate("/timesheet/view/all", {
                                state: {
                                    employee: {id: user.role.name === Roles.APPROVER ? result.employeeId : result.id},
                                    headerTitle: `${result.firstName} ${result.lastName}`
                                }
                            })
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography>
                                {`${result.firstName} ${result.lastName}`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <KeyboardArrowRightIcon/>
                        </Box>
                    </Box>
                )
            }
        )
    }

    useEffect(() => {
        getTimeLogsByCompanyPost();
    }, [])

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',

                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        marginBottom: '10px',
                    }}
                >
                    <TextField
                        sx={{
                            width: '100%',
                            background: 'rgba(0, 0, 0, 0.06)'
                        }}
                        size="small"
                        label=""
                        variant="outlined"
                        type="text"
                        onChange={event => searchEmployees(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {
                    searchResults?.length > 0 &&
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        border: "1px solid #E5E5E5",
                        marginBottom: '18px',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            borderBottom: "1px solid #E5E5E5",
                            padding: '12px 12px',
                        }}>
                            {renderSearchResults()}
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default SearchEmployeePage;