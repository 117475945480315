import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {connect} from "react-redux";

const Spinner = ({isVisible}) => {
    return (
        <Box sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            width: '100%',
            display: isVisible ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, .8)',
            zIndex: '1000',
        }}>
            <CircularProgress
                size={80}
                thickness={6}
            />
        </Box>
    );
}

const mapStateToProps = state => ({
    isVisible: state.app.showSpinner
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Spinner)