import Box from "@mui/material/Box";
import {useCallback, useState} from "react";
import DashboardComponent from "./dashboard";
import {axiosInstance} from "../../config/interceptors";
import {Constants, Errors, SnackbarMessages} from "../../contstants";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {useDispatch} from "react-redux";
import {buildRequestHeaders} from "../../utils";

const columns = [
    {field: 'name', headerName: 'Name', width: 300},
    {
        field: 'lastModifiedDate',
        headerName: 'Date Last Modified',
        width: 200,
        valueFormatter: ({value}) => value && new Date(value).toLocaleString()

    },
    {
        field: 'createdDate',
        headerName: 'Date Created',
        width: 200,
        valueFormatter: ({value}) => value && new Date(value).toLocaleString()

    },
];

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
        fieldType: 'text'
    },
    {
        name: 'name',
        required: true,
        ignore: false,
        label: 'Name',
        type: 'string',
        fieldType: 'text'
    },
]

const JobTitleDashboard = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});

    const dispatch = useDispatch();
    let snackbarMessage = {};

    const getAllRowData = () => axiosInstance.get('/admin/job-titles', buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = () => axiosInstance.get('/admin/job-title', buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            if (!field.ignore) {
                let value = params[field.name];
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        const currentDate = new Date();
        let params = {
            ...formData,
            createdDate: currentDate,
            lastModifiedDate: currentDate,
        }

        if (formMode === Constants.EDIT_MODE) {
            params = {
                ...formData,
                lastModifiedDate: new Date()
            }
        }

        if (!validateForm(params)) {
            axiosInstance.post('/admin/job-title/save', {
                ...params
            }, buildRequestHeaders()).then(response => {
                getAllRowData();

                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};

                if (callback) {
                    callback();
                }
            });
        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }

    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`/admin/job-title/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);

            snackbarMessage = SnackbarMessages.success.delete;
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.delete;

            console.error(error);
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};

            if (callback) {
                callback();
            }
        });
    }, [selectedRowData])

    return (
        <Box>
            <DashboardComponent
                dashboardName="Job Title"
                columns={columns}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
            />
        </Box>
    )
}

export default JobTitleDashboard;
