import Box from "@mui/material/Box";
import {useCallback, useEffect, useState} from "react";
import _ from "underscore";
import DashboardComponent from "./dashboard";
import {axiosInstance} from "../../config/interceptors";
import {Constants, Errors, SnackbarMessages} from "../../contstants";
import {buildRequestHeaders, convertToDropdownData} from "../../utils";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {useDispatch} from "react-redux";


const apiEndPoints = {
    save: '/admin/contribution-range/save',
    delete: '/admin/contribution-range',
    fetchAll: '/admin/contribution-ranges',
};

const columns = [
    {field: 'minRange', headerName: 'Minimum', width: 200},
    {field: 'maxRange', headerName: 'Maximum', width: 200},
    {field: 'sssER', headerName: 'ER', width: 100},
    {field: 'sssEE', headerName: 'EE', width: 100},
];

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
    },
    {
        name: 'minRange',
        required: true,
        ignore: false,
        label: 'Minimum',
        type: 'number'
    },
    {
        name: 'maxRange',
        required: true,
        ignore: false,
        label: 'Maximum',
        type: 'number',
    },
    {
        name: 'sssER',
        required: true,
        ignore: false,
        label: 'ER',
        type: 'number'
    },
    {
        name: 'sssEE',
        required: true,
        ignore: false,
        label: 'EE',
        type: 'number'
    },
]

const ContributionRange = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});

    const dispatch = useDispatch();
    let snackbarMessage = {};

    const getAllRowData = () => axiosInstance.get(apiEndPoints.fetchAll, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = () => axiosInstance.get(apiEndPoints.fetchSingle, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            if (!field.ignore) {
                let value = params[field.name];
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        let params = {
            ...formData,
        }

        if (formMode === Constants.EDIT_MODE) {
            // params = {
            //     ...formData,
            //     // inspector: {id: formData.inspector?.id}
            // }
        }

        if (!validateForm(params)) {
            axiosInstance.post(apiEndPoints.save, {
                ...params
            }, buildRequestHeaders()).then(response => {
                getAllRowData();

                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};

                if (callback) {
                    callback();
                }
            });

        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }

    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`${apiEndPoints.delete}/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);

            snackbarMessage = SnackbarMessages.success.delete;
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.delete;

            console.error(error);
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};

            if (callback) {
                callback();
            }
        });
    }, [selectedRowData])

    return (
        <Box>
            <DashboardComponent
                dashboardName="Contribution Range"
                columns={columns}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
                dropdownData={dropdownData}
            />
        </Box>
    )
}

export default ContributionRange;
