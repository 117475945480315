import {ActionType} from "../action-types";

const defaultState = {

};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ActionType.SET_USER:
            return {
                ...state,
                ...payload
            };
        case ActionType.SET_USER_TOKEN:
            return {
                ...state,
                ...payload
            };
        case ActionType.SET_USER_TYPE:
            return {
                ...state,
                userType: payload
            };
        default:
            return state;
    }
};
