import Box from "@mui/material/Box";
import {Divider, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setHeaderTitle} from "../redux/action-creators/ui";

const PayslipPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    useEffect(() => {
        dispatch(setHeaderTitle('Payslip'));
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                padding: '12px 14px',
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            textTransform: 'uppercase'
                        }}
                    >
                        Urduja Security Services Inc.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: 'rgba(0, 0, 0, .7)'
                        }}
                    >
                        Matalava, Lingayen, Pangasinan
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: 'rgba(0, 0, 0, .7)'
                        }}
                    >
                        Tel/Fax No: 632-1117
                    </Typography>
                </Box>
                <Divider sx={{
                    width: '100%',
                    margin: '14px 0',
                }}/>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '500',
                            lineHeight: '14px'
                        }}
                    >
                        {`${user.firstName} ${user.middleName} ${user.lastName}`}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: 'rgba(0, 0, 0, .7)',
                        }}
                    >{`${user?.jobTitle?.name}`}</Typography>

                    <Typography
                        sx={{
                            fontSize: '14px'
                        }}
                    >
                        {`From `}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px'
                        }}
                    >
                        {`Pay Date `}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        border: '1px solid rgba(0,0,0,.3)',
                        borderRadius: '2%',
                        marginTop: '16px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            marginBottom: '26px',
                            marginTop: '16px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Gross Salary
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Less
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography></Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                SSS
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                PhilHealth
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Pag-IBIG
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Cash Bond
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                SSS Loan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Pag-IBIG Loan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                                fontWeight: '500',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                            }}>
                                Total Deductions
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>

                    <Divider sx={{width: '95%', margin: '12px 8px'}}/>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '50%',
                                marginRight: '10px',
                            }}
                        >
                            <Typography sx={{
                                fontWeight: '500',
                                margin: '12px 0',
                            }}>
                                Net Salary
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '50%',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography>10,000.00</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PayslipPage;