import _ from "underscore";
import Box from "@mui/material/Box";
import {useCallback, useEffect, useState} from "react";
import DashboardComponent from "./dashboard";
import {axiosInstance} from "../../config/interceptors";
import {Constants, Errors, SnackbarMessages} from "../../contstants";
import {buildRequestHeaders, convertToDropdownData} from "../../utils";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {useDispatch} from "react-redux";

const apiEndPoints = {
  create: '/admin/post/new',
  update: '/admin/post/update',
  delete: '/admin/post',
  fetchAll: '/admin/posts',
  fetchSingle: '/admin/post'
};

const columns = [
    {field: 'postName', headerName: 'Name', width: 200},
    {
        field: 'cluster',
        headerName: 'Cluster',
        width: 200,
        valueFormatter: ({value}) => value && value.name
    },
    {
        field: 'company',
        headerName: 'Company',
        width: 200,
        valueFormatter: ({value}) => value && value.name
    },

    {field: 'managerFullName', headerName: 'Manager', width: 200},
    {field: 'managerEmail', headerName: 'Email', width: 200},
    {field: 'managerMobileNumber', headerName: 'Mobile', width: 200},
    {field: 'managerLandLine', headerName: 'Landline', width: 200},
];

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
    },
    {
        name: 'company',
        required: true,
        ignore: false,
        label: 'Company',
        type: 'string',
        fieldType: 'select'
    },
    {
        name: 'cluster',
        required: true,
        ignore: false,
        label: 'Cluster',
        type: 'string',
        fieldType: 'select'
    },
    {
        name: 'postName',
        required: true,
        ignore: false,
        label: 'Post Name',
        type: 'string'
    },
    {
        name: 'managerFullName',
        required: true,
        ignore: false,
        label: 'Manager Full Name',
        type: 'string'
    },
    {
        name: 'managerMobileNumber',
        required: false,
        ignore: false,
        label: 'Manager Mobile Number',
        type: 'string'
    },
    {
        name: 'managerLandLine',
        required: false,
        ignore: false,
        label: 'Manager Landline Number',
        type: 'string'
    },
    {
        name: 'managerEmail',
        required: true,
        ignore: false,
        label: 'Manager Email',
        type: 'string',
        format: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    },
    {
        name: 'lastModifiedDate',
        required: false,
        ignore: true,
        label: 'Date Last Modified',
        type: 'date',
    },
    {
        name: 'createdDate',
        required: false,
        ignore: true,
        label: 'Date Created',
        type: 'date',
        valueFormatter: params =>
            params.value ? params.value.toLocaleString() : ''
    },
]

const CompanyPostDashboard = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});

    const dispatch = useDispatch();
    let snackbarMessage = {};

    // Dropdown data
    useEffect(() => {
        axiosInstance.get('/admin/companies', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, company: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });

        axiosInstance.get('/admin/clusters', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({...prevState, cluster: convertToDropdownData(response.data, 'name', 'value')}))
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const getAllRowData = () => axiosInstance.get(apiEndPoints.fetchAll, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = () => axiosInstance.get(apiEndPoints.fetchSingle, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            let value = params[field.name];

            if (!field.ignore) {
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }

            if (!_.isNull(field.format) && !_.isUndefined(field.format)) {
                if (!value?.match(field.format)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.INVALID_VALUE
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        let params = {
            ...formData,
        }

        let url = apiEndPoints.create;
        if (formMode === Constants.EDIT_MODE) {
            params = {
                ...formData,
            }
            let clusterId = !_.isUndefined(params.cluster?.id) && !_.isNull(params.cluster?.id) ? params.cluster.id : params.cluster;
            let companyId = !_.isUndefined(params.company?.id) && !_.isNull(params.company?.id)  ? params.company.id : params.company;

            params.cluster = {id: clusterId};
            params.company = {id: companyId};

            url = apiEndPoints.update;
        } else {
            params.cluster = {id: params.cluster};
            params.company = {id: params.company};
        }

        if (!validateForm(params)) {
            axiosInstance.post(url, params, buildRequestHeaders()).then(response => {
                getAllRowData();
                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};

                if (callback) {
                    callback();
                }
            });
        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }

    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`${apiEndPoints.delete}/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);
            snackbarMessage = SnackbarMessages.success.delete;
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.delete;

            console.error(error);
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};

            if (callback) {
                callback();
            }
        });
    }, [selectedRowData])

    return (
        <Box>
            <DashboardComponent
                dashboardName="Post"
                columns={columns}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
                dropdownData={dropdownData}
            />
        </Box>
    )
}

export default CompanyPostDashboard;
