import Box from "@mui/material/Box";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PaymentsIcon from "@mui/icons-material/Payments";
import TimeInIcon from "@mui/icons-material/MoreTime";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';

import {Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import EventAvailable from "@mui/icons-material/EventAvailable";
import {Roles} from "../contstants";

function LinkIcon({type, styles, isActive, pageType}) {

    let wrapperClasses = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px'
    }

    const wrapperActiveClass = isActive ? {
        marginBottom: "16px"
    } : {};

    const linkClasses = {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 1)',
        "&:visited": {
            color: 'rgba(0, 0, 0, 1)',
        },
        "&:active": {
            color: 'rgba(0, 0, 0, 1)',
        }
    }

    let activeClass = isActive ? {
        fontSize: '40px',
    } : {};

    let icon = () => {
        if (type === 'timesheet') {
            return (
                <NavLink to={`/timesheets?pageType=${Roles.USER}`} style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <EventNoteIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>timesheet</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'payslip') {
            return (
                <NavLink to="/payslip" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <PaymentsIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>payslip</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'time-in') {
            return (
                <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>>
                    <TimeInIcon sx={{...activeClass}}/>
                    <Typography variant="span" sx={{minHeight: '20px'}}>attendance</Typography>
                </Box>
            )
        } else if (type === 'profile') {
            return (
                <NavLink to="/profile" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <PersonIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>profile</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'search') {
            return (
                <NavLink to="/search" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <SearchIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>search</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'approval') {
            return (
                <NavLink to="/timesheets" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <EventAvailable sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>approval</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'posts') {
            return (
                <NavLink to="/timesheets/inspector" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <GroupsIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>posts</Typography>
                    </Box>
                </NavLink>
            )
        } else if (type === 'attendance') {
            return (
                <NavLink to="/home" style={{...linkClasses}}>
                    <Box sx={{...wrapperClasses, ...styles, ...wrapperActiveClass}}>
                        <MoreTimeIcon sx={{...activeClass}}/>
                        <Typography variant="span" sx={{minHeight: '20px'}}>attendance</Typography>
                    </Box>
                </NavLink>
            )
        }
    }

    return (
        icon(type)
    );
}

export default LinkIcon;