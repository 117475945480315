import {FormControl, FormHelperText, MenuItem, Select, Typography} from "@mui/material";
import {Styles} from "../contstants";

function Dropdown({fieldName, onBlur = ()=>{}, onChange = ()=>{}, defaultValue, label, isRequired = false, error = {}, dropdownData}) {

    return (
        <FormControl
            sx={{
                margin: '10px 0',
                width: '100%',
            }}
            required={isRequired}
        >
            <Typography className="inputFieldLabel" variant="span" sx={{fontWeight: Styles.LABEL_FONT_WEIGHT}}>
                {label}
            </Typography>
            <Select
                variant="standard"
                sx={{height: '56px', width: '100%'}}
                defaultValue={defaultValue}
                label={label}
                onChange={event => {
                    onChange(fieldName, event.target.value)
                }}
                error={error.hasError}
                aria-describedby={`${fieldName}-error-text`}
            >
                {
                    dropdownData[fieldName] && dropdownData[fieldName].map(item => (
                        <MenuItem key={`dropdown-${item.value}`} value={item.id || item.value}>{item.description}</MenuItem>
                    ))
                }
            </Select>
            {
                error.message &&
                <FormHelperText
                    id={`${fieldName}-error-text`}
                    sx={{
                        marginLeft: '0',
                    }}
                    error
                >
                    {error.message}
                </FormHelperText>
            }
        </FormControl>
    );
}

export default Dropdown;