import {ActionType} from "../action-types";
import {SnackbarTypes} from "../../contstants";

const defaultState = {
    headerTitle: '',
    snackbar: {
        show: false,
        message: '',
        type: SnackbarTypes.SUCCESS,
    }
};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ActionType.SET_HEADER_TITLE:
            return {
                ...state,
                headerTitle: payload
            };
        case ActionType.SET_SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    show: true,
                    ...payload
                }
            }
        case ActionType.SET_HIDE_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    show: false,
                }
            }
        default:
            return state;
    }
};
