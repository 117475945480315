import Box from "@mui/material/Box";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {Typography} from "@mui/material";
import {calculateHoursFromDuration, calculateMinsFromDuration} from "../utils";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Duration = ({durationInMins, styles, textStyle = {fontSize: '16px'}, showArrow = false}) => {
    return <Box sx={{
        ...styles
    }}>
        {
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                fontSize: '12px',
            }}>
                <AccessTimeIcon sx={{...textStyle, marginRight: '6px', fontSize: '1.5rem'}}/>
                <Typography sx={{...textStyle}}
                            variant="span">{`${calculateHoursFromDuration(durationInMins)} ${calculateMinsFromDuration(durationInMins)}`}</Typography>
                {
                    showArrow &&
                    <KeyboardArrowRightIcon sx={{color: 'rgba(3,3,3,.68)', fontSize: '16px'}}/>
                }
            </Box>
        }
    </Box>
}

export default Duration;