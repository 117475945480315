import {Outlet} from 'react-router-dom';
import Box from "@mui/material/Box";

import DrawerComponent from "../drawer";
import Controls from "../controls";
import {connect} from "react-redux";
import Header from "../header";

const MobileLayout = ({headerTitle}) => {

    return (
        <Box>
            <Box
                component="content"
            >
                <Header title={headerTitle}/>
                <Box
                    sx={{
                        paddingTop: '80px',
                        height: 'calc(100% - 80px)',
                        paddingBottom: '80px',
                    }}
                >
                    <Outlet/>
                </Box>
                <Controls/>
            </Box>
        </Box>
    )

}

const mapStateToProps = state => {
    return {
        headerTitle: state.ui.headerTitle
    }
}

export default connect(mapStateToProps)(MobileLayout);