import Box from "@mui/material/Box";
import {useCallback, useEffect, useState} from "react";
import _ from "underscore";
import DashboardComponent from "./dashboard";
import {axiosInstance} from "../../config/interceptors";
import {Constants, Errors, SnackbarMessages} from "../../contstants";
import {buildRequestHeaders, convertToDropdownData} from "../../utils";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {useDispatch} from "react-redux";


const apiEndPoints = {
    save: '/admin/cluster/save',
    delete: '/admin/cluster',
    fetchAll: '/admin/clusters',
    fetchSingle: '/admin/cluster'
};

const columns = [
    {field: 'name', headerName: 'Name', width: 300},
    {
        field: 'inspector', headerName: 'Inspector', width: 200, valueFormatter: params =>
            params.value ? `${params.value.firstName} ${params.value.lastName}` : ''
    },
];

const formFields = [
    {
        name: 'id',
        required: true,
        ignore: true,
        label: '',
        type: 'number',
    },
    {
        name: 'name',
        required: true,
        ignore: false,
        label: 'Name',
        type: 'string'
    },
    {
        name: 'inspector',
        required: false,
        ignore: false,
        label: 'Inspector',
        type: 'string',
        fieldType: 'select',
    },
    {
        name: 'details',
        required: false,
        ignore: false,
        label: 'Details',
        type: 'string'
    },
]

const ClusterDashboard = () => {
    const [rowData, setRowData] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [formData, setFormData] = useState({});
    const [formMode, setFormMode] = useState(Constants.SAVE_MODE);
    const [formError, setFormError] = useState({});
    const [dropdownData, setDropdownData] = useState({});

    const dispatch = useDispatch();
    let snackbarMessage = {};

    // Dropdown data
    useEffect(() => {
        axiosInstance.get('/admin/employees/inspector', buildRequestHeaders())
            .then(response => {
                setDropdownData(prevState => ({
                    ...prevState,
                    inspector: convertToDropdownData(response.data, ['firstName', 'lastName'], 'value')
                }))
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const getAllRowData = () => axiosInstance.get(apiEndPoints.fetchAll, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const getRowData = () => axiosInstance.get(apiEndPoints.fetchSingle, buildRequestHeaders())
        .then(response => {
            setRowData(response.data);
        })
        .catch(error => {
            console.error(error);
        });

    const initializeForm = () => {
        setFormData({});
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}
        formFields.forEach(field => {
            if (!field.ignore) {
                let value = params[field.name];
                if (field.required && (!value || value.length === 0)) {
                    hasError = true
                    errors = {
                        ...errors,
                        [field.name]: {
                            hasError: true,
                            message: Errors.REQUIRED
                        }
                    }
                }
            }
        });

        setFormError(errors)
        return hasError;
    }

    /*
     * Save Data (Create/Update)
     */
    const submitCallback = useCallback((callback) => {
        let params = {
            ...formData,
            inspector: {id: formData.inspector}
        }

        if (formMode === Constants.EDIT_MODE) {
            params = {
                ...formData,
                // inspector: {id: formData.inspector?.id}
            }
        }

        if (!params.inspector?.id) {
            delete params.inspector;
        }

        if (!validateForm(params)) {
            axiosInstance.post(apiEndPoints.save, {
                ...params
            }, buildRequestHeaders()).then(response => {
                getAllRowData();

                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};

                if (callback) {
                    callback();
                }
            });

        }
    }, [formData])


    /*
     * Delete Data
     */
    const deleteRow = (callback) => {
        if (selectedRowData && selectedRowData.id) {
            deleteCallback(callback);
            initializeForm();
        } else {
            console.error("Something unexpected happen. Cannot delete record.")
        }
    }

    const deleteCallback = useCallback((callback) => {
        axiosInstance.delete(`${apiEndPoints.delete}/${selectedRowData.id}`, buildRequestHeaders()).then(response => {
            setRowData(response.data);

            snackbarMessage = SnackbarMessages.success.delete;
        }).catch(error => {
            snackbarMessage = SnackbarMessages.error.delete;

            console.error(error);
        }).finally(() => {
            dispatch(setShowSnackbar(snackbarMessage))
            snackbarMessage = {};

            if (callback) {
                callback();
            }
        });
    }, [selectedRowData])

    return (
        <Box>
            <DashboardComponent
                dashboardName="Cluster"
                columns={columns}
                rows={rowData}
                getAllRowData={getAllRowData}
                getRowData={getRowData}
                selectedRowData={selectedRowData}
                setSelectedRowData={setSelectedRowData}
                saveFormData={submitCallback}
                formFields={formFields}
                setFormData={setFormData}
                formData={formData}
                deleteRowData={deleteRow}
                formMode={formMode}
                setFormMode={setFormMode}
                formError={formError}
                setFormError={setFormError}
                dropdownData={dropdownData}
            />
        </Box>
    )
}

export default ClusterDashboard;
