import {Button, InputAdornment, Link, TextField, Typography, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import SecurityIcon from "@mui/icons-material/Security";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useDispatch} from "react-redux";
import {setUser, setUserToken} from "../redux/action-creators/user";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Roles} from "../contstants";
import {axiosInstance} from "../config/interceptors";
import {buildRequestHeaders, parseJwt} from "../utils";
import home from "./home";

const sxFlexCenter = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}

const sxColumn = {
    flexDirection: 'column'
}

const sxField = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
}

const sxFieldLabel = {
    paddingBottom: '10px',
}

function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        // On load, check if 'user' is existing in localStorage
        const token = localStorage.getItem('token');
        const decodedToken = parseJwt(token);

        if (decodedToken && decodedToken.id) {
            let user;
            // dispatch(setUser(userToken));
            let userType = "employee";
            let homePage = "/home";
            if (decodedToken.role === Roles.APPROVER) {
                userType = "client";
                homePage = "/timesheets";
            } else if (decodedToken.role === Roles.INSPECTOR) {
                userType = "employee";
                homePage = "/timesheets";
            } else if ([Roles.ADMIN, Roles.HR, Roles.PAYROLL].includes(decodedToken.role)) {
                userType = "dashboard-user";
                homePage = "/admin/"
            }

            axiosInstance.get(`/user/${userType}/${decodedToken.id}`, buildRequestHeaders()).then(response => {
                user = response.data;
                dispatch(setUser(user));
                navigate(homePage);
            }).catch(exception => {
                console.error("User not found!");
            });
        }
    },[]);

    let loginHandler = () => {
        axiosInstance.post('/authentication/authenticate', {
            username: username,
            password: password
        })
            .then(function (response) {
                const token = response.data.jwtToken;
                const decodedToken = parseJwt(token);
                let user = {};
                localStorage.setItem("token", token);

                let userType = "employee";
                let homePage = "/home";
                if (decodedToken.role === Roles.APPROVER) {
                    userType = "client";
                    homePage = "/timesheets";
                } else if (decodedToken.role === Roles.INSPECTOR) {
                    userType = "employee";
                    homePage = "/timesheets/inspector";
                } else if ([Roles.ADMIN, Roles.HR, Roles.PAYROLL].includes(decodedToken.role)) {
                    userType = "dashboard-user";
                    homePage = "/admin/"
                }

                axiosInstance.get(`/user/${userType}/${decodedToken.id}`, buildRequestHeaders()).then(response => {
                    user = response.data;
                    dispatch(setUser(user));
                    navigate(homePage);
                }).catch(exception => {
                    console.error("User not found!");
                });
            })
            .catch(function (error) {
                alert(error)
            });
    }

    const setShowPasswordOnClick = () => {
        setShowPassword(!showPassword);
    }

    return (
        <Box
            sx={{
                ...sxFlexCenter,
                ...sxColumn,
                paddingTop: {xs: '25%', md: '10%'},
                margin: '0 8%',
        }}
        >
            <Box sx={{
                maxWidth: {xs: '100%', md: 400},
                width: {xs: '100%', md: 400}
            }}>
                <Box sx={{
                    ...sxFlexCenter,
                    ...sxColumn,
                    marginBottom: '12px',
                    width: '100%'
                }}>
                    <Box><SecurityIcon sx={{fontSize: '9rem'}}/></Box>
                    <Box sx={{textTransform: 'uppercase'}}>
                        <Typography variant="h3" sx={{letterSpacing: '4px', paddingTop: '10px'}}>
                            Urduja
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{...sxFlexCenter, width: '100%'}}>
                    <Box sx={{...sxField}}>
                        <Box sx={{...sxFieldLabel}}>Username</Box>
                        <Box sx={{width: '100%'}}>
                            <TextField
                                sx={{width: '100%'}}
                                label="Enter your username"
                                variant="outlined"
                                onChange={event => setUsername(event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box sx={{...sxField}}>
                        <Box sx={{...sxFieldLabel}}>Password</Box>
                        <Box sx={{width: '100%'}}>
                            <TextField
                                sx={{width: '100%'}}
                                label="Enter your password"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                onChange={event => setPassword(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" onClick={setShowPasswordOnClick}>
                                            <VisibilityIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{...sxFlexCenter, ...sxColumn, width: '100%', marginTop: '10px'}}>
                        <Button variant="contained" sx={{
                            width: '100%'
                        }}
                                onClick={loginHandler}><Typography variant="span" sx={{
                            padding: '8px',
                            fontSize: '16px'
                        }}>Login</Typography></Button>
                        <Link href="#" sx={{width: '100%', textAlign: 'right', paddingTop: '6px'}}>Forgot your
                            password?</Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default LoginPage;
