import {Button, IconButton, TextField, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import {ZIndex} from "../contstants";

function Header({title}) {
    var showBackButton = true;
    const navigate = useNavigate();

    return (
        <Box className="header" style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            backgroundColor: '#3080FF',
            color: '#FFFFFF',
            height: '60px',
            width: '100%',
            position: 'fixed',
            top: '0',
            left: '0',
            boxShadow: '0px 4px 10px 2px rgba(0, 0, 0, 0.1)',
            zIndex: ZIndex["100"]
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    height: '100%',
                }}
            >
                {
                    showBackButton &&
                    <IconButton
                        style={{
                            color: '#FFFFFF',
                            paddingBottom: '8px'
                        }}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <KeyboardBackspaceIcon/>
                    </IconButton>
                }
                <h2 style={{
                    marginBottom: '6px'
                }}>{title}</h2>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    height: '100%',
                }}
            >
                <IconButton
                    style={{
                        color: '#FFFFFF',
                        paddingBottom: '8px'
                    }}
                    onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/login");
                    }}
                >
                    <Typography
                        sx={{
                            marginRight: '6px',
                        }}
                    >
                        Logout
                    </Typography>
                    <LogoutIcon/>
                </IconButton>
            </Box>
        </Box>
    );
}

export default Header;
