import Box from "@mui/material/Box";

import "./slider.scss";
import {SwipeableButton} from "react-swipeable-button";

function Slider({defaultText, onSlideComplete, status = 'default'}) {

    let successText = " ";
    let sliderColor = "#1565c0";

    if (status === "success") {
        sliderColor = "#acffac";
        successText = "Success!"
    } else if (status === "fail") {
        sliderColor = "#fdeded";
        successText = "Failed!"
    }

    return (
        <Box
            className={status}
            sx={{
                height: "40px",
                width: "300px",
                marginTop: "20px"
            }}
        >
            <SwipeableButton
                onSuccess={onSlideComplete}
                text={defaultText}
                text_unlocked={successText}
                color={sliderColor}
            />
        </Box>
    );
}

export default Slider;